import React, { useContext, useState } from 'react';
import { Context } from '..';
import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useValidation from '../hooks/useValidation';
import { useIntl } from 'react-intl';

const CatSelect = ({ newItem }) => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  const newCategory = useValidation(newItem.subcat, { isEmpty: true });

  const [cat, setCat] = useState('');
  const [newCat, setNew] = useState(false);
  const [subCat, setSub] = useState([]);

  const catSelect = (e) => {
    setNew(false);
    setCat(e.target.value);

    store.categories.forEach((item) => {
      if (item.id == e.target.value) setSub(item.subcats);
    });
  };

  const selectHandler = (e) => {
    if (e.target.value === 'new') {
      setNew(true);
    } else {
      setNew(false);
      if (newItem.parent) newItem.setCat(null);
      newItem.setSubcat(e.target.value);
    }
    newItem.setCat(cat);
  };

  return (
    <FormControl isRequired>
      <FormLabel>{messages.product_form_category}</FormLabel>
      <Select
        value={cat}
        name="cat"
        onChange={(e) => catSelect(e)}
        placeholder={messages.product_form_category_placeholder}
      >
        {store.categories?.message ||
          store.categories.map((el) => (
            <option value={el.id} key={el.id}>
              {el[messages.categories] || el.name}
            </option>
          ))}
      </Select>

      <FormLabel mt="10px">{messages.product_form_subcategory}</FormLabel>
      <Select
        name="subcat"
        placeholder={messages.product_form_subcategory_placeholder}
        onChange={selectHandler}
        isDisabled={!cat}
      >
        {subCat.map((el) => (
          <option value={el.id} key={el.id}>
            {el.name}
          </option>
        ))}
        <option value="new">{messages.product_form_subcategory_add}</option>
      </Select>

      {newCat && (
        <Input
          mt="10px"
          type="text"
          name="name"
          isInvalid={!!newCategory.errors.length}
          errorBorderColor="red.300"
          placeholder={messages.product_form_subcategory_add_placeholder}
          value={newItem.subcat}
          onChange={(e) => {
            newItem.setSubcat(e.target.value);
          }}
          isDisabled={store.loading}
        />
      )}
    </FormControl>
  );
};

export default observer(CatSelect);
