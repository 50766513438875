import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '.';
import useRoutes from './routes/useRoutes';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import Header from './components/Header';
import useMessage from './hooks/useMessage';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';

function App() {
  const { store } = useContext(Context);
  const routes = useRoutes(
    store.isAuth,
    store.isVendor,
    store.isActive,
    store.userData?.status
  );
  const [lang, setLang] = useState(getInitialLocale());

  function getInitialLocale() {
    // получаем сохраненные данные
    const savedLocale = localStorage.getItem('locale');
    return savedLocale || LOCALES.ENGLISH;
  }

  const langChange = (value) => {
    setLang(value);
  };
  useEffect(() => {
    if (localStorage.getItem('stk')) {
      store.checkAuth();
    }
  }, []);

  useMessage(store.error);

  return (
    <IntlProvider
      messages={messages[lang]}
      locale={lang}
      defaultLocale={LOCALES.ENGLISH}
    >
      <Header currentLocale={lang} langChange={langChange} />
      {store.loading && !store.isAuth ? (
        <Flex m="auto" minH="100vh">
          <Center flex="1">
            <Spinner thickness="4px" size="xl" color="green" />
          </Center>
        </Flex>
      ) : (
        routes
      )}
    </IntlProvider>
  );
}

export default observer(App);
