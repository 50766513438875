import React from 'react';
import { Box } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export default function InactivePage() {
  const { messages } = useIntl();

  return (
    <Box textAlign="center" mt="40px" p="10px">
      {messages.activate_message}
    </Box>
  );
}
