import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  InputRightAddon,
  InputLeftAddon,
  IconButton,
  Spinner,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Context } from '..';
import { useParams } from 'react-router-dom';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import FileUpload from '../components/FileUpload';
import MessageBox from '../components/MessageBox';
import { useIntl } from 'react-intl';

const ItemEditPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  const itemId = useParams().id;

  const [imgLoading, setImgLoading] = useState(false);
  const [name, setName] = useState(store.item.name);
  const [name_en, setNameEn] = useState(store.item.name_en);
  const [name_tr, setNameTr] = useState(store.item.name_tk);
  const [description, setDescription] = useState(store.item.description);
  const [description_en, setDescriptionEn] = useState(
    store.item.description_en
  );
  const [description_tr, setDescriptionTr] = useState(
    store.item.description_tk
  );
  const [pkg_x, setPkg_x] = useState(store.item.pkg_x);
  const [pkg_y, setPkg_y] = useState(store.item.pkg_y);
  const [pkg_z, setPkg_z] = useState(store.item.pkg_z);
  const [pkg_w, setPkg_w] = useState(store.item.pkg_w);
  const [price, setPrice] = useState(store.item.price);
  const [count, setCount] = useState(store.item.count);
  const [destination, setDestination] = useState(store.item.destination);
  const [comment, setComment] = useState(store.item.comment);

  const imgUpload = async (e) => {
    try {
      const images = [...e.target.files];
      setImgLoading(true);
      await store.uploadImage(itemId, images);
      setImgLoading(false);
    } catch (e) {
      setImgLoading(false);
    }
  };

  return (
    <Box bg="white" borderRadius="md">
      <Flex
        gap="10px"
        mt="10px"
        p="20px"
        flexDirection={['column', 'column', 'row']}
      >
        {!!store.item.images.length &&
          store.item.images.map((img) => (
            <Box
              w={['80px', '100px', '150px']}
              h={['80px', '100px', '150px']}
              key={img.id}
              borderRadius="md"
              pos="relative"
              overflow="hidden"
            >
              <Image
                pos="absolute"
                src={img.link}
                boxSize={['80px', '100px', '150px']}
                objectFit="cover"
              />
              <IconButton
                colorScheme="red"
                pos="absolute"
                right="4px"
                top="4px"
                size="sm"
                icon={<DeleteIcon />}
                onClick={() => store.delImage(img.id)}
              />
            </Box>
          ))}

        <FileUpload accept={'image/*'} multiple hendler={imgUpload}>
          {imgLoading ? (
            <Flex
              w={['80px', '100px', '150px']}
              h={['80px', '100px', '150px']}
              justifyContent="center"
              alignItems="center"
            >
              <Spinner size="md" color="green" />
            </Flex>
          ) : (
            <IconButton
              w={['80px', '100px', '150px']}
              h={['80px', '100px', '150px']}
              variant="outline"
              aria-label="Add images"
              icon={<AddIcon />}
            />
          )}
        </FileUpload>
      </Flex>
      <Flex p="20px" gap="20px" flexDirection={['column', 'column', 'row']}>
        <Box flex="1">
          <Tabs size="md" variant="enclosed">
            <TabList>
              <Tab>RU</Tab>
              <Tab>TR</Tab>
              <Tab>EN</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FormControl>
                  <FormLabel>
                    {messages.product_form_name_placeholder}{' '}
                    {messages.product_form_in_ru}
                  </FormLabel>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
                <FormControl mt="20px">
                  <FormLabel>
                    {messages.product_form_description}{' '}
                    {messages.product_form_in_ru}
                  </FormLabel>
                  <Textarea
                    py="10px"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </TabPanel>

              <TabPanel>
                <FormControl>
                  <FormLabel>
                    {messages.product_form_name_placeholder}{' '}
                    {messages.product_form_in_tr}
                  </FormLabel>
                  <Input
                    value={name_tr}
                    onChange={(e) => setNameTr(e.target.value)}
                  />
                </FormControl>
                <FormControl mt="20px">
                  <FormLabel>
                    {messages.product_form_description}{' '}
                    {messages.product_form_in_tr}
                  </FormLabel>
                  <Textarea
                    py="10px"
                    value={description_tr}
                    onChange={(e) => setDescriptionTr(e.target.value)}
                  />
                </FormControl>
              </TabPanel>

              <TabPanel>
                <FormControl>
                  <FormLabel>
                    {messages.product_form_name_placeholder}{' '}
                    {messages.product_form_in_en}
                  </FormLabel>
                  <Input
                    value={name_en}
                    onChange={(e) => setNameEn(e.target.value)}
                  />
                </FormControl>
                <FormControl mt="20px">
                  <FormLabel>
                    {messages.product_form_description}{' '}
                    {messages.product_form_in_en}
                  </FormLabel>
                  <Textarea
                    py="10px"
                    value={description_en}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                  />
                </FormControl>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <FormControl mt="20px">
            <FormLabel>{messages.product_form_comment}</FormLabel>
            <Textarea
              py="10px"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>

          <Box my="20px" p="5px" borderRadius="md" bg="#f4fae3">
            {store.item.subcat.items_cat.name} - {store.item.subcat.name} -{' '}
            <FormControl mt="20px">
              <FormLabel>{messages.product_form_destination}</FormLabel>
              <Input
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              />
            </FormControl>
          </Box>

          <FormControl mb="20px" mt="20px">
            <FormLabel>{messages.product_form_package}</FormLabel>
            <Flex gap="20px" flexDirection={['column', 'column', 'row']}>
              <InputGroup size="sm" w="100px" variant="flushed">
                <InputLeftAddon children={messages.product_x} />
                <Input
                  textAlign="center"
                  fontWeight="bold"
                  value={pkg_x}
                  onChange={(e) => setPkg_x(e.target.value)}
                />
                <InputRightAddon children={messages.product_sm} />
              </InputGroup>
              <InputGroup size="sm" w="100px" variant="flushed">
                <InputLeftAddon children={messages.product_y} />
                <Input
                  textAlign="center"
                  fontWeight="bold"
                  value={pkg_y}
                  onChange={(e) => setPkg_y(e.target.value)}
                />
                <InputRightAddon children={messages.product_sm} />
              </InputGroup>
              <InputGroup size="sm" w="100px" variant="flushed">
                <InputLeftAddon children={messages.product_z} />
                <Input
                  textAlign="center"
                  fontWeight="bold"
                  value={pkg_z}
                  onChange={(e) => setPkg_z(e.target.value)}
                />
                <InputRightAddon children={messages.product_sm} />
              </InputGroup>
              <InputGroup size="sm" w="100px" variant="flushed">
                <InputLeftAddon children={messages.product_w} />
                <Input
                  textAlign="center"
                  fontWeight="bold"
                  value={pkg_w}
                  onChange={(e) => setPkg_w(e.target.value)}
                />
                <InputRightAddon children={messages.product_kg} />
              </InputGroup>
            </Flex>
          </FormControl>
        </Box>

        <Box>
          <InputGroup mb="20px">
            <Input value={price} onChange={(e) => setPrice(e.target.value)} />
            <InputRightAddon children="$" />
          </InputGroup>

          <InputGroup mb="20px">
            <Input value={count} onChange={(e) => setCount(e.target.value)} />
            <InputRightAddon children={messages.product_form_pcs_min} />
          </InputGroup>
          <Button
            w="full"
            mb="10px"
            colorScheme="green"
            onClick={() =>
              store.updateItem({
                name,
                name_en,
                name_tr,
                description,
                description_en,
                description_tr,
                destination,
                comment,
                price,
                count,
                pkg_x,
                pkg_y,
                pkg_z,
                pkg_w,
              })
            }
          >
            {messages.product_form_save}
          </Button>
          <Button
            as={rLink}
            to={`/item/${itemId}`}
            w="full"
            mb="10px"
            colorScheme="red"
          >
            {messages.cancel}
          </Button>
        </Box>
      </Flex>
      <MessageBox store={store} to="/items" toText="Go to items" />
    </Box>
  );
};

export default observer(ItemEditPage);
