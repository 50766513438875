import { makeAutoObservable } from 'mobx';
import VendorService from '../service/VendorService';

class RegisterSeller {
  form = {
    name: '',
    description: '',
    product_types: '',
    ur_type: '',
    inn: '',
    rs: '',
    tax_com: '',
    actual_adress: '',
    registered_adress: '',
    site: '',
    email: '',
    tel: '',
    mobile_tel: '',
    index: '',
    country: '',
    city: '',
    district: '',
    manager_name: '',
    manager_tel: '',
    ceo_name: '',
    ceo_tel: '',
  };
  error = null;
  loading = false;
  store = {};

  constructor(store) {
    makeAutoObservable(this);
    this.store = store;
  }

  setForm(name, value) {
    this.form[name] = value;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(err) {
    this.error = err;
  }

  async register() {
    this.setLoading(true);
    try {
      const { data } = await VendorService.register(this.form);
      this.store.setVendor(true);
      this.store.setVendorData(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
}

export default RegisterSeller;
