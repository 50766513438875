import $api from '../http';

export default class ItemService {
  static async getItems(params) {
    return $api.get(`/vendor/items`, { params });
  }
  static async getItem(id) {
    return $api.get(`/vendor/item/${id}`);
  }

  static async addItem(itemData) {
    return $api.post('/vendor/item/add', itemData);
  }

  static async deleteItem(id) {
    return $api.delete(`/vendor/item/${id}`);
  }

  static async showHideItem(id, show) {
    return $api.put(`/vendor/item/show/${id}`, { show });
  }

  static async updateItem(id, itemData) {
    return $api.put(`/vendor/item/${id}`, itemData);
  }

  static async getCategories() {
    return $api.get('/catalog/category');
  }

  static async getSpecifications(search) {
    return $api.get('/catalog/specifications', { params: { search } });
  }

  static async delImage(id) {
    return $api.delete(`/vendor/item/deleteimage/${id}`);
  }

  static async uploadImage(data) {
    return $api.post(`/vendor/item/uploadimage`, data);
  }
}
