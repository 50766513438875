import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Spinner,
  Center,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link as rLink } from 'react-router-dom';
import { Context } from '..';

const ItemCard = ({ item }) => {
  const { store } = useContext(Context);
  if (!item) {
    return (
      <Center flex="1">
        <Spinner thickness="4px" size="xl" color="green" />
      </Center>
    );
  }
  return (
    <Flex
      mb="20px"
      borderRadius="md"
      bg="white"
      _hover={{ shadow: 'md' }}
      flexDirection={['column-reverse', 'column-reverse', 'row']}
    >
      <Flex
        as={rLink}
        to={`/item/${item.id}`}
        gap={['10px', '10px', '20px']}
        flex="1"
        flexDirection={['column', 'column', 'row']}
      >
        <Flex gap={['10px', '10px', '20px']} flex="6">
          <Box
            my={['0', '0', '10px']}
            ml="20px"
            w="100px"
            h="100px"
            bg="gray.300"
            overflow="hidden"
            borderRadius="md"
          >
            {!!item.images.length && (
              <Image
                boxSize="100px"
                objectFit="cover"
                alt={item.name}
                src={item.images[0].link}
              />
            )}
          </Box>

          <Box my={['0', '0', '20px']} px={['10px', '10px', '0']}>
            <Heading size="sm">{item.name}</Heading>
            <Box>
              {item.subcat.items_cat.name} - {item.subcat.name}
            </Box>
            <Box>{item.destination}</Box>
          </Box>
        </Flex>

        <Box
          bg={['none', 'none', '#F3FFCA']}
          px="10px"
          py={['0', '0', '10px']}
          borderRadius="md"
          my={['0', '0', '10px']}
          flex="2"
        >
          <Flex flexDirection={['row', 'row', 'column']}>
            <Text mr="10px">Package:</Text>
            <Box>
              {item.pkg_x} x {item.pkg_y} x {item.pkg_z} sm
            </Box>
          </Flex>
          <Box>Weight: {item.pkg_w} kg</Box>
        </Box>

        <Flex
          py={['0', '0', '10px']}
          mb={['20px', '20px', '0']}
          pr="20px"
          flexDirection={['row-reverse', 'row-reverse', 'column']}
          alignItems="baseline"
          flex="1"
        >
          <Box>
            <Text
              fontWeight="bold"
              color="green"
              fontSize={['3xl', '3xl', 'xl']}
            >
              {item.price} $
            </Text>
          </Box>
          <Box mr={['10px', '10px', '0']}>{item.count} pcs/lot</Box>
        </Flex>
      </Flex>
      <Box alignSelf={['end', 'stretch', 'stretch', 'stretch']}>
        {item.show ? (
          <IconButton
            icon={<ViewIcon />}
            colorScheme="green"
            size={['md', 'md', 'sm']}
            h={['24px', 'full', 'full', 'full']}
            onClick={() => store.showHide(item.id, item.show)}
            isLoading={store.loading}
          >
            Hide
          </IconButton>
        ) : (
          <IconButton
            icon={<ViewOffIcon />}
            colorScheme="yellow"
            size={['md', 'md', 'sm']}
            h={['24px', 'full', 'full', 'full']}
            onClick={() => store.showHide(item.id, item.show)}
            isLoading={store.loading}
          >
            Show
          </IconButton>
        )}
      </Box>
    </Flex>
  );
};

export default observer(ItemCard);
