import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  IconButton,
  Heading,
  Divider,
  Flex,
  Tag,
  Center,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import VendorData from '../components/VendorData';
import { Context } from '..';
import { Link as rLink } from 'react-router-dom';

export const tag = {
  created: 'orange',
  active: 'green',
  blocking: 'red',
};

const VendorInfoPage = () => {
  const { store } = useContext(Context);

  useEffect(() => {
    store.getVendorData();
  }, []);

  return (
    <Box p={['15px', '15px', '30px']} bg="white" borderRadius="md">
      <Flex gap="20px">
        <Heading flex="1" size="md">
          {store.vendorData?.name}
        </Heading>
        <Box>
          <Tag colorScheme={tag[store.vendorData?.status]} borderRadius="full">
            {store.vendorData?.status}
          </Tag>
          <IconButton
            as={rLink}
            to="edit"
            size="sm"
            ml="20px"
            borderRadius="full"
            aria-label="Edit"
            colorScheme="red"
            icon={<EditIcon />}
          />
        </Box>
      </Flex>
      <Divider my="20px" />

      <VendorData vendor={store.vendorData} />
    </Box>
  );
};

export default observer(VendorInfoPage);
