import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  VStack,
  Heading,
  Divider,
  Button,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link as rLink } from 'react-router-dom';
import { Context } from '..';
import useInput from '../hooks/useInput';
import MessageBox from '../components/MessageBox';

const RestorePassword = () => {
  const { store } = useContext(Context);
  const login = useInput('', { isEmpty: true });
  const pass = useInput('', { isEmpty: true });
  const pass2 = useInput('', {
    isEmpty: true,
    compare: pass,
  });
  const code = useInput('', { isEmpty: true });

  const authHandler = () => {
    store.restore(login.props.value);
  };

  useEffect(() => {
    return () => {
      store.setRestore(null);
    };
  }, []);

  return (
    <Flex direction="column" mt="100px" align="center">
      <Box w="90%" maxW="500px" m="auto">
        <Box bg="white" borderRadius="md" mb="20px" p="30px" boxShadow="lg">
          <VStack spacing="20px">
            <Box>
              <Heading size="md">Restore password</Heading>
            </Box>
            <Divider />

            {store.code ? (
              <>
                <FormControl>
                  <FormLabel>Code from email or sms</FormLabel>
                  <Input
                    placeholder="Enter code"
                    name="pass"
                    {...code.props}
                    isDisabled={store.loading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>New password</FormLabel>
                  <Input
                    placeholder="New password"
                    name="pass"
                    {...pass.props}
                    isDisabled={store.loading}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Confirm password</FormLabel>
                  <Input
                    placeholder="Confirm password"
                    name="pass2"
                    {...pass2.props}
                    isDisabled={store.loading}
                  />
                </FormControl>
                <Button
                  colorScheme="green"
                  px="40px"
                  onClick={() =>
                    store.updatePass(code.props.value, pass.props.value)
                  }
                  isLoading={store.loading}
                  isDisabled={pass.err || pass2.err || code.err}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <FormControl>
                  <FormLabel>Login or email</FormLabel>
                  <Input
                    placeholder="E-mail or login"
                    name="login"
                    {...login.props}
                    isDisabled={store.loading}
                  />
                </FormControl>

                <Button
                  colorScheme="green"
                  px="40px"
                  onClick={authHandler}
                  isLoading={store.loading}
                  isDisabled={login.err}
                >
                  Send
                </Button>
              </>
            )}

            <Button as={rLink} size="xs" variant="ghost" w="full" to="/login">
              Login
            </Button>
          </VStack>
        </Box>
      </Box>

      <MessageBox store={store} to="/login" toText="Login" />
    </Flex>
  );
};

export default observer(RestorePassword);
