import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  Select,
  InputGroup,
  InputRightElement,
  Checkbox,
  Link,
  Center,
} from '@chakra-ui/react';
import Social from '../components/Social';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as rLink } from 'react-router-dom';
import useInput from '../hooks/useInput';
import { observer } from 'mobx-react-lite';
import SmsForm from '../components/SmsForm';
import useMessage from '../hooks/useMessage';
import useValidation from '../hooks/useValidation';
import { useIntl } from 'react-intl';

const RegisterPage = ({ registerStore }) => {
  const { messages } = useIntl();

  const email = useValidation(registerStore.email, {
    email: true,
    isEmpty: true,
  });
  const phone = useValidation(registerStore.phone, {
    isEmpty: true,
    length: 10,
  });
  const password = useValidation(registerStore.password, { isEmpty: true });
  const password2 = useInput('', {
    isEmpty: true,
    compare: registerStore.password,
  });

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [policy, setPolicy] = useState(false);
  const policyValidation = useValidation(policy, { isEmpty: true });

  const inputCard = useRef();

  const handleChange = () => {
    const cardValue = inputCard.current.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `${cardValue[1]}-${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ''
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, '');
    registerStore.setPhone(numbers);
  };

  useEffect(() => {
    handleChange();
  }, [registerStore.phone]);

  useMessage(registerStore.error);

  return (
    <>
      <Flex direction="column" mt={['20px', '20px', '100px']} align="center">
        <Box w="90%" maxW="500px" m="auto">
          <Box bg="white" borderRadius="md" mb="20px" p="30px" boxShadow="lg">
            <VStack spacing="20px">
              <Box>
                <Heading size="md">{messages.reg_form_title}</Heading>
              </Box>
              <Divider />
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="text"
                  name="email"
                  placeholder="E-mail"
                  value={registerStore.email}
                  onChange={(e) => registerStore.setEmail(e.target.value)}
                  isDisabled={registerStore.loading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{messages.form_tel_title}</FormLabel>
                <InputGroup>
                  <Select
                    onChange={(e) => registerStore.setCode(e.target.value)}
                    name="code"
                    w="100px"
                    mr="10px"
                  >
                    <option value="+7">+7</option>
                    <option value="+90">+90</option>
                  </Select>
                  <Input
                    ref={inputCard}
                    type="text"
                    name="phone"
                    placeholder={messages.form_tel_placeholder}
                    onChange={handleChange}
                    isDisabled={registerStore.loading}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{messages.form_pass_label}</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password"
                    placeholder={messages.form_pass_placeholder}
                    value={registerStore.password}
                    onChange={(e) => registerStore.setPassword(e.target.value)}
                    isDisabled={registerStore.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{messages.form_passconfirm_label}</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password2"
                    placeholder={messages.form_passconfirm_label}
                    {...password2.props}
                    isDisabled={registerStore.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Checkbox
                colorScheme="green"
                onChange={(e) => setPolicy(e.target.checked)}
              >
                {messages.form_policy}{' '}
                <Link as={rLink} to="/policy" color="green" fontWeight="medium">
                  {messages.form_policy_link}
                </Link>
              </Checkbox>

              <Button
                colorScheme="green"
                px="40px"
                onClick={() => registerStore.register()}
                isLoading={registerStore.loading}
                isDisabled={
                  email.err ||
                  password.err ||
                  phone.err ||
                  password2.err ||
                  policyValidation.err
                }
              >
                {messages.register_button}
              </Button>

              <Button as={rLink} size="xs" variant="ghost" w="full" to="/enter">
                {messages.login_form_title}
              </Button>
            </VStack>
          </Box>
          <Center>
            <Social />
          </Center>
        </Box>
      </Flex>

      <SmsForm registerStore={registerStore} />
    </>
  );
};

export default observer(RegisterPage);
