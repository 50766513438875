import { makeAutoObservable } from 'mobx';
import OrderService from '../service/OrderService';
import AuthService from '../service/AuthService';
import ItemService from '../service/ItemService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';

export default class Store {
  isAuth = false;
  loading = false;
  error = null;
  itemsList = [];
  itemsCount = null;
  item = null;
  editItem = {};
  isVendor = false;
  isActive = false;
  userData = null;
  vendorData = null;
  orders = [];
  categories = [];
  message = null;
  code = null;
  stockImages = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(err) {
    this.error = err;
  }

  setRestore(code) {
    this.code = code;
  }

  clearError() {
    this.error = null;
  }

  setVendor(bool) {
    this.isVendor = bool;
  }

  setVendorValues(name, value) {
    this.vendorData[name] = value;
  }

  setActive(bool) {
    this.isActive = bool;
  }

  setUserData(user) {
    this.userData = user;
  }

  setVendorData(vendor) {
    this.vendorData = vendor;
  }

  setOrders(orders) {
    this.orders = orders;
  }

  setStockImages(images = []) {
    this.stockImages = images;
  }

  updateOrderItem(orderItem) {
    const oidx = this.orders.findIndex((ord) => ord.id === orderItem.id);
    // const idx = this.orders[oidx].items.findIndex((item) => item.id === itemId);
    this.orders[oidx] = orderItem;
  }

  setCategories(categories) {
    this.categories = categories;
  }

  setItem(item) {
    this.item = item;
  }

  setNewImages(imgArr) {
    this.item?.images.push(...imgArr);
  }
  removeImage(imgId) {
    if (this.item?.images.length) {
      this.item.images = this.item.images.filter((img) => img.id != imgId);
    }
  }

  setEditItem(field) {
    this.editItem = { ...this.editItem, ...field };
  }

  setMessage(msg) {
    this.message = msg;
  }

  setItems(items) {
    this.itemsList = items;
  }
  setCount(count) {
    this.itemsCount = count;
  }

  setShow(id, bool) {
    this.itemsList.forEach((item) => {
      if (item.id === id) item.show = bool;
    });
    if (this.item && this.item?.id === id) {
      this.setItem({ ...this.item, show: bool });
    }
  }

  async login(login, password) {
    this.setLoading(true);
    try {
      const response = await AuthService.login(login, password);
      localStorage.setItem('stk', response.data.accessToken);
      this.checkAuth();
      //this.setAuth(true);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async restore(login) {
    this.setLoading(true);
    try {
      const { data } = await AuthService.restore(login);
      this.setRestore(data.restoreCode);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updatePass(code, password) {
    this.setLoading(true);
    try {
      const { data } = await AuthService.updatePass(code, password);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async logout() {
    this.setLoading(true);
    try {
      await AuthService.logout();
      localStorage.removeItem('stk');
      this.setAuth(false);
      this.setVendor(false);
      this.setUserData(null);
      this.setVendorData(null);
      this.setItems([]);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const { data } = await UserService.getCurrent();
      const { vendor, ...user } = data;
      this.setUserData(user);
      if (vendor?.mid) {
        this.setVendor(true);
        if (vendor?.status === 'active') this.setActive(true);
        else this.setActive(false);
        this.setVendorData(vendor);
      }
      this.setAuth(true);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getVendorData() {
    this.setLoading(true);
    try {
      const { data } = await VendorService.getData(this.vendorData.mid);
      this.setVendorData(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateUser(userData) {
    this.setLoading(true);
    try {
      const { data } = await UserService.update(userData);
      this.setUserData(data.data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getItems(search = {}) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.getItems({
        vid: this.vendorData.mid,
        ...search,
      });
      this.setItems(data.items);
      this.setCount(data.count);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getItem(id) {
    // const find = this.itemsList.find((item) => item.id == id);
    // if (find) {
    //   this.setItem(find);
    //   return;
    // }
    this.setLoading(true);
    try {
      const { data } = await ItemService.getItem(id);
      this.setItem(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  // async updateItem(itemData) {
  //   this.setLoading(true);
  //   try {
  //     const { data } = await ItemService.updateItem(itemData);
  //   } catch (e) {
  //     this.setError(e);
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }

  async deleteItem(itemId) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.deleteItem(itemId);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateVendor() {
    this.setLoading(true);
    try {
      const { data } = await VendorService.update(this.vendorData);
      this.setMessage('Ok');
      //this.vendorData = data;
      //await this.checkAuth();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrders(filter) {
    this.setLoading(true);
    try {
      const { data } = await VendorService.getOrderItems(filter);
      this.setOrders(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  get newOrders() {
    const newActiveOrders = this.orders.filter(
      (order) => order.status === 'processing'
    );

    return newActiveOrders;
  }

  async getCategories() {
    this.setLoading(true);
    try {
      const { data } = await ItemService.getCategories();
      this.setCategories(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkOrderItem(id, fields = {}) {
    this.setLoading(true);
    try {
      const { data } = await VendorService.checkOrderItem({ id, ...fields });
      this.updateOrderItem(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendItem(id) {
    this.setLoading(true);
    try {
      const { data } = await VendorService.sendItem(id);
      this.updateOrderItem(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async delImage(id) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.delImage(id);
      this.removeImage(id);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async uploadImage(itemId, images) {
    try {
      const formData = new FormData();

      formData.append('itemId', itemId);

      images.forEach((img) => {
        formData.append('images', img);
      });

      const { data } = await ItemService.uploadImage(formData);
      this.setNewImages(data);
    } catch (e) {
      this.setError(e);
    }
  }

  async showHide(id, show) {
    this.setLoading(true);
    try {
      const { data } = await ItemService.showHideItem(id, !show);
      if (data.message) {
        this.setShow(id, !show);
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateItem(itemObj) {
    this.setLoading(true);
    try {
      const sendData = {};
      for (const key in itemObj) {
        if (itemObj[key] != this.item[key]) {
          sendData[key] = itemObj[key];
        }
      }
      const { data } = await ItemService.updateItem(this.item.id, sendData);
      if (data?.message) this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  get newOrderItems() {
    const orderItems = this.orders.map((order) => order.items);
    return orderItems.filter((item) => item.vConfirm === false).length;
  }

  async sendSupport(title, message) {
    this.setLoading(true);
    try {
      const { data } = await UserService.sendToSupport(title, message);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getStockImages(id) {
    try {
      const { data } = await VendorService.getStockImages(id);
      this.setStockImages(data);
    } catch (e) {
      this.setError(e);
    }
  }
}
