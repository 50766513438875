import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import Footer from '../components/Footer';
import { useNotifications } from '../hooks/useNotifications';
import { Context } from '..';

const MainPage = () => {
  const { store } = useContext(Context);
  useNotifications(store.vendorData?.mid);

  return (
    <>
      <Box w="full" px="8px" mt="40px">
        <Box minH="90vh">
          <Flex
            maxW="1100px"
            direction="column"
            px={['10px', '15px', '20px']}
            mx="auto"
          >
            <Outlet />
            <Spacer />
            <Box h="10px"></Box>
          </Flex>
        </Box>
        {/* <Box mt="20px" h="200px" borderTopRadius="md"></Box> */}
      </Box>
      <Footer />
    </>
  );
};

export default observer(MainPage);
