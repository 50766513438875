import React, { useState } from 'react';
import { Box, Text, Flex, Spacer, Link, Grid, Button } from '@chakra-ui/react';
import Social from './Social';
import SupportForm from './SupportForm';
import { useIntl } from 'react-intl';

const Footer = () => {
  const { messages } = useIntl();
  const [support, setSupport] = useState(false);
  return (
    <>
      <Box mt="100px">
        <Box bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)">
          <Box
            bg="#f4fae3"
            borderBottomRadius="md"
            boxShadow="lg"
            h="20px"
            w="full"
          ></Box>
          <Flex flexDirection={['column', 'column', 'row']}>
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1"
              p="20px"
              gap={['8px', '10px', '20px']}
              justifyItems="center"
            >
              <Box>
                <Button
                  size="sm"
                  variant="ghost"
                  color="white"
                  _hover={{ color: 'black', bg: 'whiteAlpha.300' }}
                  onClick={() => setSupport(true)}
                >
                  {messages.link_support}
                </Button>
              </Box>
              <Box color="blackAlpha.700">
                <Box>
                  <Link href="https://promo.turkpazar.ru" isExternal>
                    {messages.link_about}
                  </Link>
                </Box>
                <Box>
                  <Link href="https://turkpazar.ru" isExternal>
                    {messages.link_catalog}
                  </Link>
                </Box>
              </Box>
              <Box></Box>
              <Box>
                <Social />
              </Box>
            </Grid>
            <Box
              px={['0', '0', '50px']}
              pb={['0', '0', '50px']}
              pt={['0', '0', '20px']}
              color="gray.200"
            >
              <Box
                px="14px"
                py="4px"
                borderBottomRadius={['none', 'none', 'lg']}
                borderTopRadius="lg"
                bg="blackAlpha.600"
                textAlign={['center']}
              >
                <Text as="span" color="#9DC41A">
                  Turk
                </Text>
                <Text as="span" color="#FCC330">
                  Pazar
                </Text>
                <Text as="span" mx="10px">
                  &copy;
                </Text>
                <Text as="span">2023</Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>

      <SupportForm opened={support} close={setSupport} />
    </>
  );
};

export default Footer;
