import {
  Box,
  Heading,
  Flex,
  Spacer,
  Tag,
  Accordion,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import OrderCard from '../components/OrderCard';
import { useIntl } from 'react-intl';

const OrdersListPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);

  useEffect(() => {
    store.getOrders();
  }, []);

  return (
    <>
      <Flex p="20px" bg="white" borderRadius="md" alignItems="center">
        <Heading fontSize={['lg', 'xl', '3xl']}>
          {messages.page_order_requests}
        </Heading>
        <Spacer />
        <Box>
          {!!store.newOrderItems && (
            <Tag borderRadius="full" variant="outline" colorScheme="red">
              Not confirmed items - {store.newOrderItems}
            </Tag>
          )}
        </Box>
      </Flex>
      <Box my="20px">
        Tüm ürünleri siparişte ki numara ile barkodlayip aşağıdaki depoya
        göndermeniz gerekmektedir:
        <Text fontWeight="bold">
          Hürriyet Tüccarı Cd, Kızıltaş sk. No 45, Pegas. Nişanca -
          Kumkapı / İstanbul
        </Text>
      </Box>
      <Box mt={['20px', '30px', '40px']}>
        <Accordion allowToggle>
          {store.orders
            ? store.orders.map((item) => (
                <OrderCard key={item.id} order={item} />
              ))
            : 'No orders found'}
        </Accordion>
      </Box>
    </>
  );
};

export default observer(OrdersListPage);
