import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  VStack,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useValidation from '../hooks/useValidation';
import useMessage from '../hooks/useMessage';
import { useIntl } from 'react-intl';
import { Link as rLink } from 'react-router-dom';
import MessageBox from './MessageBox';

const VendorForm = ({ vendorStore }) => {
  const { messages } = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ur_type = useValidation(vendorStore.vendorData.ur_type, {
    isEmpty: true,
  });
  const inn = useValidation(vendorStore.vendorData.inn, { isEmpty: true });
  const rs = useValidation(vendorStore.vendorData.rs, { isEmpty: true });
  // const tax_com = useValidation(vendorStore.form.tax_com, { isEmpty: true });
  const actual_adress = useValidation(vendorStore.vendorData.actual_adress, {
    isEmpty: true,
  });
  const registered_adress = useValidation(
    vendorStore.vendorData.registered_adress,
    {
      isEmpty: true,
    }
  );
  const email = useValidation(vendorStore.vendorData.email, {
    isEmpty: true,
    isEmail: true,
  });
  // const tel = useValidation(vendorStore.form.tel, { isEmpty: true });
  const index = useValidation(vendorStore.vendorData.index, { isEmpty: true });
  const country = useValidation(vendorStore.vendorData.country, {
    isEmpty: true,
  });
  const city = useValidation(vendorStore.vendorData.city, { isEmpty: true });
  const district = useValidation(vendorStore.vendorData.district, {
    isEmpty: true,
  });
  const ceo_name = useValidation(vendorStore.vendorData.ceo_name, {
    isEmpty: true,
  });
  const ceo_tel = useValidation(vendorStore.vendorData.ceo_tel, {
    isEmpty: true,
  });

  const [copy, setCopy] = useState(false);

  const changeHandler = (e) => {
    vendorStore.setVendorValues(e.target.name, e.target.value);
    if (copy && e.target.name == 'actual_adress') {
      vendorStore.setVendorValues('registered_adress', e.target.value);
    }
  };

  const copyAddress = () => {
    setCopy(!copy);
  };
  useEffect(() => {
    if (copy) {
      vendorStore.setVendorValues(
        'registered_adress',
        vendorStore.vendorData.actual_adress
      );
    } else {
      vendorStore.setVendorValues(
        'registered_adress',
        vendorStore.vendorData.registered_adress
      );
    }
  }, [copy]);

  useEffect(() => {
    if (
      vendorStore.vendorData.registered_adress ===
      vendorStore.vendorData.actual_adress
    ) {
      setCopy(true);
    }
  }, []);

  useEffect(() => {
    if (vendorStore.message) onOpen();
  }, [vendorStore.message]);

  return (
    <Box>
      <VStack gap="10px">
        <Heading size="lg">{vendorStore.vendorData.name}</Heading>
        <FormControl>
          <FormLabel>{messages.vendor_description}</FormLabel>
          <Textarea
            name="description"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.description}
            isDisabled={vendorStore.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{messages.vendor_ur_type}</FormLabel>
          <Select
            name="ur_type"
            placeholder={messages.vendor_select_ur_type}
            onChange={changeHandler}
            value={vendorStore.vendorData.ur_type}
            isDisabled={vendorStore.loading}
          >
            <option value="Gerçek Kişi Tacir">Gerçek Kişi Tacir</option>
            <option value="İrtibat Bürosu">İrtibat Bürosu</option>
            <option value="Yabancı Şirket Şubesi">Yabancı Şirket Şubesi</option>
            <option value="Adi Ortaklık">Adi Ortaklık</option>
            <option value="Kooperatif">Kooperatif</option>
            <option value="Kollektif Şirket">Kollektif Şirket</option>
            <option value="Komandit Şirket">Komandit Şirket</option>
            <option value="Anonim Şirket">Anonim Şirket</option>
            <option value="Limited Şirket">Limited Şirket</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_inn}</FormLabel>
          <Input
            type="number"
            name="inn"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.inn}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_rs}</FormLabel>
          <Input
            type="number"
            name="rs"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.rs}
            isDisabled={vendorStore.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{messages.vendor_actual_address}</FormLabel>
          <Input
            type="text"
            name="actual_adress"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.actual_adress}
            isDisabled={vendorStore.loading}
          />
          <Checkbox isChecked={copy} onChange={copyAddress}>
            {messages.vendor_same_address}
          </Checkbox>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{messages.vendor_register_address}</FormLabel>
          <Input
            type="text"
            name="registered_adress"
            placeholder=""
            onChange={changeHandler}
            value={
              copy
                ? vendorStore.vendorData.actual_adress
                : vendorStore.vendorData.registered_adress
            }
            isDisabled={vendorStore.loading || copy}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_website}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="https://" />
            <Input
              type="text"
              name="site"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.vendorData.site}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>E-mail</FormLabel>
          <Input
            type="text"
            name="email"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.email}
            isDisabled={vendorStore.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{messages.vendor_zip}</FormLabel>
          <Input
            type="text"
            name="index"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.index}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_country}</FormLabel>
          <Input
            type="text"
            name="country"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.country}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_city}</FormLabel>
          <Input
            type="text"
            name="city"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.city}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_district}</FormLabel>
          <Input
            type="text"
            name="district"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.district}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_manager_name}</FormLabel>
          <Input
            type="text"
            name="manager_name"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.manager_name}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_manager_tel}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="manager_tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.vendorData.manager_tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_ceo_name}</FormLabel>
          <Input
            type="text"
            name="ceo_name"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.vendorData.ceo_name}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_ceo_tel}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="ceo_tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.vendorData.ceo_tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>

        <Flex gap="20px">
          <Button
            colorScheme="green"
            w="100px"
            onClick={() => vendorStore.updateVendor()}
            isLoading={vendorStore.loading}
            isDisabled={
              ur_type.err ||
              inn.err ||
              rs.err ||
              // tax_com.err ||
              actual_adress.err ||
              registered_adress.err ||
              email.err ||
              // tel.err ||
              index.err ||
              country.err ||
              city.err ||
              district.err ||
              ceo_name.err ||
              ceo_tel.err
            }
          >
            {messages.user_save}
          </Button>
          <Button as={rLink} to="/shop" w="100px" colorScheme="red">
            {messages.cancel}
          </Button>
        </Flex>
      </VStack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={() => {
          vendorStore.checkAuth();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Vendor data updated</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default observer(VendorForm);
