import { useEffect, useRef } from 'react';

const useAutoload = (ref, loading, call) => {
  const observer = useRef();

  useEffect(() => {
    if (loading) return;

    if (observer.current) observer.current.disconnect();
    const callback = function (entires, obs) {
      if (entires[0].isIntersecting) {
        call();
      }
    };
    observer.current = new IntersectionObserver(callback);
    observer.current.observe(ref.current);
  }, [loading]);
};

export default useAutoload;
