import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '..';
import { Box, Button, Flex, VStack, Heading, Divider } from '@chakra-ui/react';
import VendorForm from '../components/VendorForm';
import RegisterSeller from '../store/registerSeller';
import { useIntl } from 'react-intl';

const RegisterVendorPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  const seller = new RegisterSeller(store);
  return (
    <Flex direction="column" mt="40px" align="center">
      <Box w="90%" maxW="800px" m="auto">
        <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
          <VStack spacing="20px">
            <Box>
              <Heading size="md">{messages.vendor_form_title}</Heading>
            </Box>
            <Divider />

            <VendorForm vendorStore={seller} />
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default observer(RegisterVendorPage);
