import {
  SettingsIcon,
  ExternalLinkIcon,
  EditIcon,
  AddIcon,
  CalendarIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Context } from '..';
import { Link as rLink } from 'react-router-dom';
import { messages } from '../i18n/messages';
import { useIntl } from 'react-intl';

const UserMenu = () => {
  const { store } = useContext(Context);
  const { messages } = useIntl();
  return (
    <Box>
      <Menu>
        <MenuButton zIndex="10" pos="relative">
          <HStack>
            <Avatar
              src={store.userData.avatar}
              size={['md', 'lg']}
              border="4px"
            />
            <Text
              color="gray.500"
              fontWeight="medium"
              display={['none', 'inline', 'inline', 'inline']}
            >
              {store.userData.login || store.userData.email}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList
          mt={['0px', '0px', '-26px']}
          pt={['10px', '10px', '27px']}
          borderTop="none"
          borderTopRadius={['md', 'md', '0']}
        >
          <MenuItem
            as={rLink}
            to="/setup"
            icon={<SettingsIcon boxSize="20px" color="gray.600" />}
          >
            {messages.menu_user_setup}
          </MenuItem>
          <MenuItem
            as={rLink}
            to="/shop"
            icon={<CalendarIcon boxSize="20px" color="gray.600" />}
          >
            {messages.menu_shop_info}
          </MenuItem>

          <MenuItem
            as={rLink}
            to="/additem"
            icon={<AddIcon boxSize="20px" color="gray.600" />}
            display={['block', 'block', 'none', 'none']}
          >
            {messages.page_add_product}
          </MenuItem>
          <MenuItem
            as={rLink}
            to="/items"
            icon={<HamburgerIcon boxSize="20px" color="gray.600" />}
            display={['block', 'block', 'none', 'none']}
          >
            {messages.page_product_list}
          </MenuItem>
          <MenuItem
            as={rLink}
            to="/orders"
            icon={<EditIcon boxSize="20px" color="gray.600" />}
            display={['block', 'block', 'none', 'none']}
          >
            {messages.page_orders}
          </MenuItem>

          <MenuDivider />
          <MenuItem
            icon={<ExternalLinkIcon boxSize="20px" color="gray.600" />}
            onClick={() => store.logout()}
          >
            {messages.menu_logout}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserMenu;
