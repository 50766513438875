import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from './store/store';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { main } from './themes';

const store = new Store();

export const Context = createContext({ store });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{ store }}>
    <ChakraProvider theme={main}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </Context.Provider>
);
