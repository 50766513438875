import {
  Box,
  Flex,
  Heading,
  Spacer,
  Tag,
  Center,
  Spinner,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '..';
import ItemCard from '../components/ItemCard';
import { useIntl } from 'react-intl';
import Pagination from '../components/Pagination';

const ItemListPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!store.loading) setLoading(false);
  }, [store.loading]);

  if (loading)
    return (
      <Center>
        <Spinner thickness="4px" size="xl" color="green" />
      </Center>
    );

  return (
    <>
      <Flex p="20px" bg="white" borderRadius="md" alignItems="center">
        <Heading fontSize={['lg', 'xl', '2xl']}>
          {messages.page_my_products}
        </Heading>
        <Spacer />
        <Box>
          <Tag borderRadius="full" variant="outline" colorScheme="green">
            {messages.page_all_items} - {store.itemsCount}
          </Tag>
        </Box>
      </Flex>
      <Box mt={['20px', '30px', '40px']}>
        {store.itemsList.map((item) => (
          <ItemCard key={item.id} item={item} />
        ))}
      </Box>

      <Pagination
        itemsCount={store.itemsCount}
        isLoading={store.loading}
        callback={(...args) => store.getItems(...args)}
      />
    </>
  );
};

export default ItemListPage;
