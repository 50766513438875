import React, { useContext } from 'react';
import { Box, Button, Flex, HStack, Spacer } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import UserMenu from './UserMenu';
import Logo from './Logo';
import Lang from './Lang';
import { useIntl } from 'react-intl';

const Header = ({ currentLocale, langChange }) => {
  const { store } = useContext(Context);
  const { messages } = useIntl();

  return (
    <Box
      w="full"
      pb="5px"
      boxShadow="lg"
      bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)"
      borderBottomRadius="md"
    >
      <Box h="5px"></Box>
      <Box
        bg="white"
        pos="absolute"
        h={['40px', '62px']}
        w="full"
        zIndex="0"
      ></Box>
      <Flex
        px="20px"
        maxW="1100px"
        m="auto"
        mb="5px"
        alignItems="end"
        alignContent="end"
        zIndex="10"
        pos="relative"
        justify="center"
      >
        <Logo />

        {store.isAuth && (
          <>
            <Spacer />
            <HStack mb={['0', '10px']} alignSelf="end">
              {store.isVendor && store.isActive && (
                <Box display={['none', 'none', 'inline', 'inline']}>
                  <Button as={rLink} to="/additem" variant="ghost">
                    {messages.page_add_product}
                  </Button>
                  <Button as={rLink} to="/items" variant="ghost">
                    {messages.page_product_list}
                  </Button>
                  <Button as={rLink} to="/orders" variant="ghost">
                    {messages.page_orders}
                  </Button>
                </Box>
              )}
              <UserMenu />
            </HStack>
          </>
        )}
      </Flex>

      <Lang langChange={langChange} currentLocale={currentLocale} />
    </Box>
  );
};

export default observer(Header);
