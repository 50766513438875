import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { LOCALES } from '../i18n/locales';

const Lang = ({ currentLocale, langChange }) => {
  const languages = [
    { name: 'English', code: LOCALES.ENGLISH, img: 'en.gif' },
    { name: 'Русский', code: LOCALES.RUSSIAN, img: 'ru.gif' },
    { name: 'Turkey', code: LOCALES.TURKEY, img: 'tk.gif' },
  ];
  const [selected, setSelected] = useState(
    languages.find((item) => item.code === currentLocale)
  );
  const [langs, setLangs] = useState(
    languages.filter((item) => item.code !== selected.code)
  );

  const selectLang = (lang) => {
    setLangs(languages.filter((item) => item.code !== lang.code));
    setSelected(languages.find((item) => item.code === lang.code));
    langChange(lang.code);
  };

  return (
    <Box pos="absolute" top="0" right="10px" zIndex="7777">
      <Menu>
        <MenuButton
          borderRadius="full"
          borderWidth="2px"
          borderColor="white"
          boxShadow="md"
          overflow="hidden"
        >
          <Image src={selected.img} boxSize="20px" objectFit="cover" />
        </MenuButton>

        <MenuList minWidth="auto">
          {langs.map((item) => (
            <MenuItem key={item.code} as={Box} onClick={() => selectLang(item)}>
              <Image src={item.img} boxSize="20px" objectFit="cover" />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Lang;
