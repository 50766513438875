import { LOCALES } from './locales';

export const messages = {
  [LOCALES.ENGLISH]: {
    login_form_title: 'Authorization',
    form_login_label: 'Login',
    form_login_placeholder: 'E-mail, phone number or login',
    form_pass_label: 'Password',
    form_pass_placeholder: 'Enter password',
    login_button: 'Login',
    register_button: 'Registration',
    forgot_button: 'Forgot password?',
    cancel: 'Cancel',

    reg_form_title: 'Registration',
    form_tel_title: 'Mobile phone',
    form_tel_placeholder: 'Mobile phone number',
    form_passconfirm_label: 'Confirm password',
    form_passconfirm_placeholder: 'Confirm password',
    form_policy: 'I agree with ',
    form_policy_link: 'the privacy policy',

    vendor_form_title: 'Vendor Registration',
    vendor_name: 'Organiztion name',
    vendor_description: 'Organization description',
    vendor_ur_type: 'Legal entity type',
    vendor_select_ur_type: 'Select type',
    vendor_inn: 'INN',
    vendor_rs: 'Current account',
    vendor_actual_address: 'Actual address',
    vendor_same_address: 'The legal address is the same as the actual address',
    vendor_register_address: 'Registered address',
    vendor_website: 'Website',
    vendor_zip: 'Zip Code',
    vendor_country: 'Country',
    vendor_city: 'City',
    vendor_district: 'District',
    vendor_manager_name: 'Manager name',
    vendor_manager_tel: 'Manager phone number',
    vendor_ceo_name: 'CEO name',
    vendor_ceo_tel: 'CEO phone number',

    menu_logout: 'Logout',
    menu_user_setup: 'User setup',
    menu_shop_info: 'My shop info',

    page_no_confirm:
      'We verify the data of your company. After verification you will get access to the service',

    page_add_product: 'Add product',
    page_product_list: 'Product list',
    page_orders: 'Orders',
    page_my_products: 'My products',
    page_order_requests: 'Orders requests',
    page_orders_empty: 'New order requests is empty',
    page_all_items: 'All items',

    product_x: 'Width',
    product_y: 'Height',
    product_z: 'Length',
    product_w: 'Weight',
    product_sm: 'sm',
    product_kg: 'kg',
    product_form_name: 'Name',
    product_form_name_placeholder: 'Product name',
    product_form_in_ru: 'in russian',
    product_form_in_en: 'in english',
    product_form_in_tr: 'in turkey',
    product_form_description: 'Description',
    product_form_comment: 'Comment. Only the manager can see',
    product_form_description_placeholder: 'Add product description',
    product_form_price: 'Price of lot',
    product_form_price_placeholder: 'Price of lot',
    product_form_pcs: 'Pcs. in lot',
    product_form_pcs_placeholder: 'Pcs. in lot',
    product_form_pcs_min: 'Pcs./lot',
    product_form_category: 'Category',
    product_form_category_placeholder: 'Select category',
    product_form_subcategory: 'Subcategory',
    product_form_subcategory_placeholder: 'Select subcategory',
    product_form_subcategory_add: 'Add new',
    product_form_subcategory_add_placeholder: 'Enter new subcategory',
    product_form_destination: 'Destination',
    product_form_destination_placeholder: 'Product destination',
    product_form_package: 'Package specifications',
    product_form_spec: 'Specifications',
    product_form_spec_title: 'Add color, size, and other specifications',
    product_form_spec_button: 'Add',
    product_form_add_spec: 'Add specification',
    product_form_images: 'Upload product images',
    product_form_save: 'Save product',

    link_support: 'Contact technical support',
    link_about: 'About',
    link_catalog: 'Catalog',

    support_form_title: 'Question to tech support',
    support_title: 'Title',
    support_description: 'Describe the problem',
    support_button: 'Send',

    user_name: 'Name',
    user_sname: 'Second name',
    user_tel: 'Phone',
    user_upload_image: 'Upload image',
    user_save: 'Save',
    categories: 'name_tr',

    sms_form_email_title: 'Enter the code from your email',
    sms_form_phone_title: 'Enter the last 4 digits of the called number',
    sms_form_sms_title: 'Enter SMS code',
    sms_form_phone_text: 'No text message? Get your code by',
    sms_form_phone_link: 'phone call',
    sms_form_email_text: 'No one has called?',
    sms_form_email_link: 'Get a code at',

    max_size: 'The file size should not exceed 5MB',

    activate_message:
      'A letter for account activation has been sent to your e-mail address specified during registration.',
  },
  [LOCALES.RUSSIAN]: {
    login_form_title: 'Авторизация',
    form_login_label: 'Логин',
    form_login_placeholder: 'E-mail, номер телефона или логин',
    form_pass_label: 'Пароль',
    form_pass_placeholder: 'Введите пароль',
    login_button: 'Войти',
    register_button: 'Зарегистрироваться',
    forgot_button: 'Забыли пароль?',
    cancel: 'Отмена',

    reg_form_title: 'Регистрация',
    form_tel_title: 'Номер телефона',
    form_tel_placeholder: 'Введите номер телефона',
    form_passconfirm_label: 'Подтвердите пароль',
    form_passconfirm_placeholder: '',
    form_policy: 'Я согласен с ',
    form_policy_link: 'политикой конфиденциальности',

    vendor_form_label: 'Регистрация завода',
    vendor_name: 'Название фирмы',
    vendor_description: 'Описание',
    vendor_ur_type: 'Вид юридического лица',
    vendor_select_ur_type: 'Выберете вид юр. лица',
    vendor_inn: 'ИНН',
    vendor_rs: 'Расчетный счет',
    vendor_actual_address: 'Фактический адрес',
    vendor_same_address: 'Юридический адрес совпадаетс фактическим',
    vendor_register_address: 'Юридический адрес',
    vendor_website: 'Сайт',
    vendor_zip: 'Почтовый индекс',
    vendor_country: 'Страна',
    vendor_city: 'Город',
    vendor_district: 'Район',
    vendor_manager_name: 'Имя контактного лица',
    vendor_manager_tel: 'Телефон контактного лица',
    vendor_ceo_name: 'Имя директора',
    vendor_ceo_tel: 'Телефон директора',

    menu_logout: 'Выйти',
    menu_user_setup: 'Настройки пользователя',
    menu_shop_info: 'Данные завода',

    page_no_confirm:
      'Мы проверяем данные вашей компании. После проверки вы получите доступ к сервису',

    page_add_product: 'Добавить товар',
    page_product_list: 'Товары',
    page_orders: 'Заявки',
    page_my_products: 'Мои товары',
    page_order_requests: 'Заявки на товары',
    page_orders_empty: 'Список заявок пуст',
    page_all_items: 'Всего товаров',

    product_x: 'Ширина',
    product_y: 'Высота',
    product_z: 'Глубина',
    product_w: 'Вес',
    product_sm: 'см',
    product_kg: 'кг',
    product_form_name: 'Название',
    product_form_name_placeholder: 'Название товара',
    product_form_in_ru: 'на русском',
    product_form_in_en: 'на английском',
    product_form_in_tr: 'на турецком',
    product_form_description: 'Описание',
    product_form_comment: 'Коментарий. Видно только менеджеру',
    product_form_description_placeholder: 'Опишите товар',
    product_form_price: 'Цена',
    product_form_price_placeholder: 'Цена за лот',
    product_form_pcs: 'Колличество',
    product_form_pcs_placeholder: 'Колличество в лоте',
    product_form_pcs_min: 'шт./лот',
    product_form_category: 'Категория',
    product_form_category_placeholder: 'Выберете категорию',
    product_form_subcategory: 'Подкатегория',
    product_form_subcategory_placeholder: 'Выберете подкатегорию',
    product_form_subcategory_add: 'Добавить новую',
    product_form_subcategory_add_placeholder: 'Введите подкатегорию',
    product_form_destination: 'Назначение',
    product_form_destination_placeholder: 'Назначение товара',
    product_form_package: 'Упаковка',
    product_form_spec: 'Характеристики',
    product_form_spec_title: 'Добавить цвет, размер и другие характеристики',
    product_form_spec_button: 'Добавить',
    product_form_add_spec: 'Добавить характеристику',
    product_form_images: 'Загрузить фото товара',
    product_form_save: 'Сохранить товар',

    link_support: 'Сообщение в техподдержку',
    link_about: 'О компании',
    link_catalog: 'Каталог',

    support_form_title: 'Вопрос в техподдержку',
    support_title: 'Заголовок',
    support_description: 'Опишите проблему',
    support_button: 'Отправить',

    user_name: 'Имя',
    user_sname: 'Отчество',
    user_tel: 'Телефон',
    user_upload_image: 'Загрузить изображение',
    user_save: 'Сохранить',
    categories: 'name',

    sms_form_email_title: 'Введите код из e-mail',
    sms_form_phone_title: 'Введите последние 4 цифры позвонившего гомера',
    sms_form_sms_title: 'Введите код из СМС',
    sms_form_phone_text: 'Не пришло СМС?',
    sms_form_phone_link: 'Отправить код звонком',
    sms_form_email_text: 'Не поступил звонок?',
    sms_form_email_link: 'Отправить код на',

    max_size: 'Размер файлов не должен превышать 5МБ',

    activate_message:
      'На ваш e-mail указанный при регистрации отправлено письмо для активации аккаунта',
  },
  [LOCALES.TURKEY]: {
    login_form_title: 'Üye girişi',
    form_login_label: 'Giriş',
    form_login_placeholder: 'E-mail, cep no veya kullanıcı adı',
    form_pass_label: 'Şifre',
    form_pass_placeholder: 'Şifreyi girin',
    login_button: 'Giriş',
    register_button: 'Üye ol',
    forgot_button: 'Şifrenizi mi unuttunuz?',
    cancel: 'İptal',

    reg_form_title: 'Kayıt',
    form_tel_title: 'Telefon numarası',
    form_tel_placeholder: 'Telefon numarasını girin',
    form_passconfirm_label: 'Şifreyi onayla',
    form_passconfirm_placeholder: '',
    form_policy: 'Gizlilik ',
    form_policy_link: 'politikasını onaylıyorum',

    vendor_form_label: 'Fabrika kaydı',
    vendor_name: 'Şirket adı',
    vendor_description: 'Açıklama',
    vendor_ur_type: 'Tüzel kişilik türü',
    vendor_select_ur_type: 'Tüzel kişilik türünü seçin',
    vendor_inn: 'Vergi No',
    vendor_rs: 'Cari hesap / İBAN',
    vendor_actual_address: 'Gerçek adres',
    vendor_same_address: 'Yasal adres gerçek adresle aynıdır',
    vendor_register_address: 'Yasal adres',
    vendor_website: 'Web sitesi',
    vendor_zip: 'Posta kodu',
    vendor_country: 'Ülke',
    vendor_city: 'İl',
    vendor_district: 'İlçe',
    vendor_manager_name: 'İrtibat kurulacak kişinin adı',
    vendor_manager_tel: 'İlgili kişinin telefon numarası',
    vendor_ceo_name: 'Kurucu / Müdür adı',
    vendor_ceo_tel: 'Kurucu/Müdür telefon numarası',

    menu_logout: 'Logout',
    menu_user_setup: 'Kullanıcı ayarları',
    menu_shop_info: 'Tesis verileri',

    page_no_confirm:
      'Şirket verilerinizi kontrol ediyoruz. Doğrulandıktan sonra hizmete erişiminiz sağlanacaktır',

    page_add_product: 'Ürün ekle',
    page_product_list: 'Ürünler',
    page_orders: 'Siparişler',
    page_my_products: 'Ürünlerim',
    page_order_requests: 'Mal talepleri',
    page_orders_empty: 'İstek listesi boş',
    page_all_items: 'Toplam ürün',

    product_x: 'Genişlik',
    product_y: 'Yükseklik',
    product_z: 'Derinlik',
    product_w: 'Ağırlık',
    product_sm: 'cm',
    product_kg: 'kg',
    product_form_name: 'İsim',
    product_form_name_placeholder: 'Ürün adı',
    product_form_in_ru: 'на русском',
    product_form_in_en: 'на английском',
    product_form_in_tr: 'Название товара на английском',
    product_form_description: 'Açıklama',
    product_form_comment: 'Yorum yap. Sadece yönetici görebilir',
    product_form_description_placeholder: 'Ürünü tanımlayın',
    product_form_price: 'Fiyat',
    product_form_price_placeholder: 'Paket fiyatı ne dir?',
    product_form_pcs: 'Miktar',
    product_form_pcs_placeholder: 'Pakette ürün adedi nedir?',
    product_form_pcs_min: 'pcs/lot',
    product_form_category: 'Kategori',
    product_form_category_placeholder: 'Bir kategori seçin',
    product_form_subcategory: 'Alt Kategori',
    product_form_subcategory_placeholder: 'Bir alt kategori seçin',
    product_form_subcategory_add: 'Yeni ekle',
    product_form_subcategory_add_placeholder: 'Alt kategori girin',
    product_form_destination: 'Ürün amacı',
    product_form_destination_placeholder: 'Nerede veya nasıl kullanılır?',
    product_form_package: 'Ambalaj',
    product_form_spec: 'Özellikler',
    product_form_spec_title: 'Renk, boyut ve diğer özellikleri ekleyin',
    product_form_spec_button: 'Ekle',
    product_form_add_spec: 'Ek özellikler varsa ekleyin',
    product_form_images: 'Ürün resmi yükle',
    product_form_save: 'Ürünü Kaydet',

    link_support: 'Teknik desteğe mesaj',
    link_about: 'Şirket Hakkında',
    link_catalog: 'Katalog',

    support_form_title: 'Teknik desteğe soru',
    support_title: 'Başlık',
    support_description: 'Sorunu tanımlayın',
    support_button: 'Gönder',

    user_name: 'İlk İsim',
    user_sname: 'göbek adı',
    user_tel: 'Telefon numarası',
    user_upload_image: 'Resim yükle',
    user_save: 'Kaydet',
    categories: 'name_en',

    sms_form_email_title: 'E-postanızdaki kodu girin',
    sms_form_phone_title: 'Bir telefon araması ile kod alın',
    sms_form_sms_title: 'SMS kodunu girin',
    sms_form_phone_text: 'SMS gelmedi mi?',
    sms_form_phone_link: 'Bir telefon araması ile kod alın',
    sms_form_email_text: 'Çağrı gelmedi mı?',
    sms_form_email_link: ' E-posta`ya kod al',

    max_size: 'Dosyalar 5 MB`tan büyük olmamalıdır',

    activate_message:
      'Kayıt olduğuniz e-posta adresinize hesabınızı aktifleştirmek için bir mektup gönderildi.',
  },
};
