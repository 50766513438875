import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '..';
import { Box, Divider, Flex, Heading, Spinner, Center } from '@chakra-ui/react';
import ItemMinCard from '../components/ItemMinCard';
import OrderItemsMinCard from '../components/OrderItemsMinCard';
import { Link as rLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

const VendorMainPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  useEffect(() => {
    store.getItems({ limit: 10 });
    store.getOrders({ vConfirm: false });
  }, []);

  return (
    <Flex
      gap="20px"
      flexDirection={['column-reverse', 'column-reverse', 'row']}
    >
      <Box p="20px" flex="1" bg="white" borderRadius="md" boxShadow="lg">
        <Flex>
          <Heading as={rLink} to="/items" flex="1" size="md">
            {messages.page_my_products}
          </Heading>
          <Box as={rLink} to="/items">
            {messages.page_all_items} - {store.itemsCount}
          </Box>
        </Flex>
        <Divider mt="10px" mb="20px" />
        {store.itemsList.map((item) => (
          <ItemMinCard key={item.id} item={item} />
        ))}
      </Box>
      <Box p="20px" flex="1" bg="white" borderRadius="md" boxShadow="lg">
        <Heading as={rLink} to="/orders" size="md">
          {messages.page_order_requests}
        </Heading>
        <Divider mt="10px" mb="20px" />
        {store.newOrders.length
          ? store.newOrders.map((item) => (
              <OrderItemsMinCard key={item.id} order={item} />
            ))
          : messages.page_orders_empty}
      </Box>
    </Flex>
  );
};

export default observer(VendorMainPage);
