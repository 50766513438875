import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const SmsForm = ({ registerStore }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [call, setCall] = useState(false);
  const [email, setEmail] = useState(false);
  const { messages } = useIntl();

  const getCall = async () => {
    await registerStore.resendCode();
    setCall(true);
  };

  const getEmail = async () => {
    await registerStore.resendCode('email');
    setEmail(true);
  };

  return (
    <Modal isOpen={registerStore.isEnterCode} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {call
            ? email
              ? messages.sms_form_email_title
              : messages.sms_form_phone_title
            : messages.sms_form_sms_title}
        </ModalHeader>
        <ModalBody>
          <Center>
            {registerStore.loading ? (
              <Spinner />
            ) : (
              <>
                <HStack>
                  <PinInput
                    onComplete={(value) => registerStore.checkPhone(value)}
                    isInvalid={!!registerStore.message}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            )}
          </Center>
          {!!registerStore.message && (
            <Box mt="20px" w="full" textAlign="center" color="red">
              {registerStore.message}
            </Box>
          )}
          <Box mt="20px" textAlign="center">
            {!call ? (
              <Text>
                {messages.sms_form_phone_text}{' '}
                <Link color="blue" onClick={() => getCall()}>
                  {messages.sms_form_phone_link}
                </Link>
              </Text>
            ) : (
              <Text>
                {messages.sms_form_email_text}{' '}
                <Link color="blue" onClick={() => getEmail()}>
                  {messages.sms_form_email_link}
                </Link>{' '}
                {registerStore.email}
              </Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Center mt="20px">
            <Button
              variant="ghost"
              size="xs"
              onClick={() => {
                registerStore.setEnterCode(false);
                setCall(false);
                setEmail(false);
              }}
            >
              {messages.cancel}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(SmsForm);
