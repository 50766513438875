import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon, ChatIcon } from '@chakra-ui/icons';
import React, { useContext } from 'react';
import { Context } from '..';
import { observer } from 'mobx-react-lite';

const OrderItemsMinCard = ({ order }) => {
  const { store } = useContext(Context);
  console.log(store);
  return (
    <Flex gap="10px">
      <Box>{order.code}</Box>
      <Box flex="1">
        {order.items.map(
          (item) =>
            item.vConfirm || (
              <Flex key={item.id} gap="10px" mb="20px">
                <Box w="50px" h="50px" bg="gray.100">
                  {!!item.images.length && (
                    <Image
                      boxSize="50px"
                      objectFit="cover"
                      src={item.images[0].link}
                    />
                  )}
                </Box>
                <Box flex="1">
                  {item.name}
                  <Box>
                    {item.price}$ / {item.count}pcs.
                  </Box>
                </Box>
                <Box
                  borderX="1px"
                  borderColor="gray.300"
                  px="10px"
                  textAlign="center"
                >
                  <Text>Count</Text>
                  <Text as="strong">{item.orderCount}</Text>
                </Box>
                <VStack>
                  <IconButton
                    colorScheme="green"
                    size="xs"
                    icon={<CheckIcon />}
                    onClick={() => store.checkOrderItem(item.id)}
                  />
                  <IconButton
                    colorScheme="orange"
                    size="xs"
                    icon={<ChatIcon />}
                  />
                </VStack>
              </Flex>
            )
        )}
      </Box>
      <Box></Box>
    </Flex>
  );
};

export default observer(OrderItemsMinCard);
