import $api from '../http';

export default class VendorService {
  static async register(vendorData) {
    return $api.post('/vendor/register', vendorData);
  }

  static async getData(uid) {
    return $api.get('/vendor/', uid);
  }

  static async update(vendorData) {
    return $api.put('/vendor/', vendorData);
  }

  static async getOrderItems(params) {
    return $api.get('/vendor/orders', { params });
  }

  static async checkOrderItem(data) {
    return $api.post('/vendor/checkitem', { ...data });
  }

  static async sendItem(id) {
    return $api.get(`/vendor/senditem/${id}`);
  }

  static async getStockImages(id) {
    return $api.get(`/orders/stock/images/${id}`);
  }
}
