import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Center,
  Spacer,
  Heading,
  Divider,
  Text,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import CatSelect from '../components/CatSelect';
import Specifications from '../components/Specifications';
import useValidation from '../hooks/useValidation';
import ImgForm from '../components/ImgForm';
import MessageBox from '../components/MessageBox';
import { useIntl } from 'react-intl';
// import { useNavigate } from 'react-router-dom';

const ItemAddPage = ({ itemStore }) => {
  const { messages } = useIntl();
  //const { store } = useContext(Context);
  // const navigate = useNavigate();

  const name = useValidation(itemStore.name, { isEmpty: true });
  const description = useValidation(itemStore.description, { isEmpty: true });
  const destination = useValidation(itemStore.destination, { isEmpty: true });
  const pkg_x = useValidation(itemStore.pkg_x, { isEmpty: true });
  const pkg_y = useValidation(itemStore.pkg_y, { isEmpty: true });
  const pkg_z = useValidation(itemStore.pkg_z, { isEmpty: true });
  const pkg_w = useValidation(itemStore.pkg_w, { isEmpty: true });
  const price = useValidation(itemStore.price, { isEmpty: true });
  const lot = useValidation(itemStore.count, { isEmpty: true });

  const sendForm = async () => {
    await itemStore.sendForm();
    // itemStore.store.getCategories();
  };

  useEffect(() => {
    if (!itemStore.store.categories.length) itemStore.store.getCategories();
  }, []);

  return (
    <Box bg="white" borderRadius="md" p={['15px', '20px', '40px']}>
      <Flex alignItems="center">
        <Heading size={['md', 'xl', '2xl']}>
          {messages.page_add_product}
        </Heading>
        <Spacer />
        <Button
          size={['sm', 'sm', 'md']}
          colorScheme="green"
          onClick={sendForm}
          isLoading={itemStore.loading}
          isDisabled={
            name.err ||
            description.err ||
            destination.err ||
            pkg_x.err ||
            pkg_y.err ||
            pkg_z.err ||
            pkg_w.err ||
            price.err ||
            lot.err ||
            itemStore.formSize > 5
          }
        >
          {messages.product_form_save}
        </Button>
      </Flex>
      <Divider mb="40px" mt="10px" />

      <Box flex="1">
        <FormControl
          isRequired
          as={Flex}
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
        >
          <FormLabel>{messages.product_form_name}</FormLabel>
          <Input
            maxW={['auto', 'auto', '80%']}
            type="text"
            name="name"
            placeholder={messages.product_form_name_placeholder}
            value={itemStore.name}
            onChange={(e) => itemStore.setValue(e)}
            isDisabled={itemStore.loading}
          />
        </FormControl>

        <FormControl
          mt="20px"
          isRequired
          as={Flex}
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
        >
          <FormLabel>{messages.product_form_description}</FormLabel>
          <Textarea
            maxW={['auto', 'auto', '80%']}
            name="description"
            placeholder={messages.product_form_description_placeholder}
            value={itemStore.description}
            onChange={(e) => itemStore.setValue(e)}
            isDisabled={itemStore.loading}
          />
        </FormControl>

        <FormControl
          mt="20px"
          as={Flex}
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          bg="gray.100"
          p="5px"
          borderRadius="5px"
        >
          <FormLabel>{messages.product_form_comment}</FormLabel>
          <Textarea
            maxW={['auto', 'auto', '80%']}
            name="comment"
            placeholder=""
            value={itemStore.comment}
            onChange={(e) => itemStore.setValue(e)}
            isDisabled={itemStore.loading}
          />
        </FormControl>
      </Box>

      <Flex gap="40px" mt="40px" flexDirection={['column', 'column', 'row']}>
        <FormControl isRequired as={Flex} justifyContent="space-between">
          <FormLabel>{messages.product_form_price}</FormLabel>
          <InputGroup maxW="70%">
            <Input
              type="number"
              name="price"
              placeholder={messages.product_form_price_placeholder}
              value={itemStore.price}
              onChange={(e) => itemStore.setValue(e)}
              isDisabled={itemStore.loading}
            />
            <InputRightAddon children="$" />
          </InputGroup>
        </FormControl>

        <FormControl isRequired as={Flex} justifyContent="space-between">
          <FormLabel>{messages.product_form_pcs}</FormLabel>
          <Input
            maxW="70%"
            type="number"
            name="count"
            placeholder={messages.product_form_pcs_placeholder}
            value={itemStore.count}
            onChange={(e) => itemStore.setValue(e)}
            isDisabled={itemStore.loading}
          />
        </FormControl>
      </Flex>

      <Flex mt="40px" gap="20px" flexDirection={['column', 'column', 'row']}>
        <CatSelect newItem={itemStore} />
        <FormControl isRequired maxW={['auto', 'auto', '40%']}>
          <FormLabel>{messages.product_form_destination}</FormLabel>
          <Input
            type="text"
            name="destination"
            placeholder={messages.product_form_destination_placeholder}
            value={itemStore.destination}
            onChange={(e) => itemStore.setValue(e)}
            isDisabled={itemStore.loading}
          />
        </FormControl>
      </Flex>

      <Box
        border="1px"
        borderColor="gray.100"
        mt="40px"
        borderRadius="md"
        p="20px"
      >
        <FormControl isRequired>
          <FormLabel>{messages.product_form_package}</FormLabel>
          <Flex
            gap="20px"
            justifyContent="center"
            flexDirection={['column', 'column', 'row']}
          >
            <Flex justifyContent="space-between">
              <Box>{messages.product_x}</Box>
              <Input
                textAlign="center"
                width="60px"
                type="number"
                name="pkg_x"
                size="sm"
                mx="10px"
                value={itemStore.pkg_x}
                onChange={(e) => itemStore.setValue(e)}
                isDisabled={itemStore.loading}
              />
              <Text> {messages.product_sm}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Box>{messages.product_y}</Box>
              <Input
                textAlign="center"
                width="60px"
                type="number"
                name="pkg_y"
                size="sm"
                mx="10px"
                value={itemStore.pkg_y}
                onChange={(e) => itemStore.setValue(e)}
                isDisabled={itemStore.loading}
              />
              <Text> {messages.product_sm}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Box>{messages.product_z}</Box>
              <Input
                textAlign="center"
                width="60px"
                type="number"
                name="pkg_z"
                size="sm"
                mx="10px"
                value={itemStore.pkg_z}
                onChange={(e) => itemStore.setValue(e)}
                isDisabled={itemStore.loading}
              />
              <Text> {messages.product_sm}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Box>{messages.product_w}</Box>
              <Input
                textAlign="center"
                width="60px"
                type="number"
                name="pkg_w"
                size="sm"
                mx="10px"
                value={itemStore.pkg_w}
                onChange={(e) => itemStore.setValue(e)}
                isDisabled={itemStore.loading}
              />
              <Text>{messages.product_kg}</Text>
            </Flex>
          </Flex>
        </FormControl>
      </Box>

      <Center mt="40px">
        <FormControl isRequired>
          <FormLabel>{messages.product_form_spec}</FormLabel>
          <Specifications store={itemStore} />
        </FormControl>
      </Center>

      <ImgForm store={itemStore} />

      <Center mt="20px">
        <Button
          colorScheme="green"
          onClick={sendForm}
          isLoading={itemStore.loading}
          isDisabled={
            name.err ||
            description.err ||
            destination.err ||
            pkg_x.err ||
            pkg_y.err ||
            pkg_z.err ||
            pkg_w.err ||
            price.err ||
            lot.err ||
            itemStore.formSize > 5
          }
        >
          {messages.product_form_save}
        </Button>
      </Center>

      <MessageBox store={itemStore} to="/items" toText="Go to items" />
    </Box>
  );
};

export default observer(ItemAddPage);
