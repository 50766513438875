import React, { useState, useContext } from 'react';
import { Box, Flex, Image, Tag, Text, Button, Tr, Td } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import StockImages from './StockImages';
import { CheckIcon, ChatIcon, WarningIcon } from '@chakra-ui/icons';
import { Context } from '..';
import ConfirmForm from './ConfirmForm';

export const orderItemTag = {
  'wait confirm': ['gray.200', 'Ждет подтверждения'],
  modified: ['orange.200', 'Изменен'],
  checked: ['green.200', 'Подтвержден'],
  deleted: ['red.200', 'Удален'],
  send: ['teal.200', 'Отправлен'],
  recive: ['cyan.200', 'На складе'],
  updated: ['green.200', 'Обновлен'],
  wrong: ['red.200', 'Несоответствие'],
  mismatch: ['orange.200', 'Несоответствие на складе'],
};

const OrderItemCard = ({ item, order, loading }) => {
  const { store } = useContext(Context);
  const [form, setForm] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [wrong, setWrong] = useState(false);

  return (
    <Box
      mb="20px"
      bg={item.status === 'modified' ? 'orange.100' : wrong && 'orange.100'}
      py="5px"
      pl={['5px', '5px', '0']}
      pr="5px"
      borderRightRadius="md"
      borderLeftRadius={['md', 'md', '0']}
    >
      <Flex
        gap={['10px', '10px', '20px']}
        flexDir={['column', 'column', 'row']}
      >
        <Flex
          gap={['5px', '10px', '20px']}
          flexDir={['column', 'column', 'row']}
        >
          <Flex
            bg={orderItemTag[item.status][0]}
            fontSize="xs"
            borderRightRadius="md"
            borderLeftRadius={['md', 'md', '0']}
            px="10px"
            alignItems="center"
          >
            {orderItemTag[item.status][1]}
          </Flex>
          <Box
            textAlign={['left', 'left', 'right']}
            fontWeight="bold"
            color="green.500"
          >
            {item.code}
          </Box>
          <Flex
            gap={['10px', '10px', '20px']}
            flexDir={['column', 'column', 'row']}
          >
            <Flex gap="20px">
              <Box>
                {!!item.images.length && (
                  <Image
                    objectFit="cover"
                    boxSize="50px"
                    src={item.images[0].link}
                  />
                )}
              </Box>
              <Box>
                <Box>{item.name}</Box>
                <Box fontSize="12px">
                  Упаковка: {item.pkg_x} x {item.pkg_y} x {item.pkg_z} Вес:{' '}
                  {item.pkg_w}
                </Box>
              </Box>
            </Flex>

            <Box>
              <Flex flexDir={['row', 'row', 'column']} gap="10px">
                <Text fontSize="xs">
                  Колличество в заказе - {item.orderCount}
                </Text>
                {item.factCount === 0 ? (
                  <Text>Нет в наличии</Text>
                ) : (
                  item.factCount &&
                  item.factCount < item.orderCount && (
                    <Text color="red" fontWeight="bold" fontSize="xs">
                      В наличии - {item.factCount}
                    </Text>
                  )
                )}

                {item.stockCount && (
                  <Text fontSize="xs">
                    Поступило на склад - {item.stockCount}
                  </Text>
                )}
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex
          flex="1"
          gap="20px"
          justifyContent={['space-between', 'flex-end', 'space-between']}
        >
          <Box>
            {item.stockDate && (
              <>
                <Button onClick={() => setPhotos(true)} size="xs" h="full">
                  Фото со склада
                </Button>
                <StockImages
                  orderItem={item.id}
                  isOpen={photos}
                  onClose={() => setPhotos(false)}
                />
              </>
            )}
          </Box>

          <Box>
            {item.status === 'checked' && order.status === 'paid' && (
              <Button
                colorScheme="green"
                h="full"
                size="xs"
                onClick={() => store.sendItem(item.id)}
                isLoading={loading}
              >
                Send
              </Button>
            )}

            {item.status === 'wait confirm' && (
              <>
                <Button
                  w="full"
                  leftIcon={<CheckIcon />}
                  colorScheme="green"
                  size="xs"
                  onClick={() => store.checkOrderItem(item.id)}
                  isLoading={loading}
                >
                  Confirm
                </Button>
                <Button
                  w="full"
                  leftIcon={<WarningIcon />}
                  colorScheme="orange"
                  size="xs"
                  onClick={() => setForm(true)}
                >
                  Modify
                </Button>
                <ConfirmForm
                  opened={form}
                  close={setForm}
                  itemId={item.id}
                  orderId={order.id}
                  loading={loading}
                />
              </>
            )}
          </Box>
        </Flex>
      </Flex>
      {item.status === 'wrong' && (
        <Flex mt="10px" ml="20px" gap="20px">
          <Text fontWeight="bold" fontSize="sm" color="red">
            Customer comment
          </Text>
          <Text>{item.wrong}</Text>
        </Flex>
      )}
    </Box>
    // <Flex
    //   gap={['10px', '10px', '20px']}
    //   mb="20px"
    //   alignItems={['stretch', 'stretch', 'stretch']}
    //   flexDirection={['column', 'column', 'row']}
    // >
    //   <Box bg={orderItemTag[item.status]} textAlign="center" w="60px">
    //     {item.status}
    //   </Box>
    //   <Flex flex="1" gap={['10px', '10px', '20px']}>
    //     <Box w="50px" h="50px" bg="gray.100">
    //       {!!item.images.length && (
    //         <Image objectFit="cover" boxSize="50px" src={item.images[0].link} />
    //       )}
    //     </Box>
    //     <Box flex="1">
    //       <Box>{item.name}</Box>
    //       <Box fontSize="12px">
    //         Упаковка: {item.pkg_x} x {item.pkg_y} x {item.pkg_z} Вес:{' '}
    //         {item.pkg_w}
    //       </Box>
    //     </Box>
    //   </Flex>
    //   <Flex justifyContent="space-between" gap="10px">
    //     <Flex
    //       flexDirection={['row', 'row', 'column']}
    //       gap="10px"
    //       textAlign="center"
    //       borderX="1px"
    //       borderColor="gray.300"
    //       px="20px"
    //     >
    //       <Text>Колличество в заказе</Text>
    //       <Text as="strong">{item.orderCount}</Text>
    //       {item.factCount == 0 && <Text>Нет в наличии</Text>}
    //     </Flex>
    //   </Flex>
    // </Flex>
  );
};

export default OrderItemCard;
