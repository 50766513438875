import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context } from '..';
import ItemAddPage from '../pages/ItemAddPage';
import ItemListPage from '../pages/ItemListPage';
import OrdersListPage from '../pages/OrdersListPage';
import ItemPage from '../pages/ItemPage';
import LoginPage from '../pages/LoginPage';
import MainPage from '../pages/MainPage';
import RegisterPage from '../pages/RegisterPage';
import RegisterVendorPage from '../pages/RegisterVendorPage';
import UserSetupPage from '../pages/UserSetupPage';
import VendorInfoPage from '../pages/VendorInfoPage';
import VendorEditPage from '../pages/VendorEditPage';
import VendorMainPage from '../pages/VendorMainPage';
import NoConfirmPage from '../pages/NoConfirmPage';
import ItemEditPage from '../pages/ItemEditPage';

import NewItem from '../store/newItem';
import RegisterUser from '../store/registerUser';
import Policy from '../pages/Policy';
import RestorePassword from '../pages/RestorePassword';
import InactivePage from '../pages/InactivePage';

const useRoutes = (isAuth, isVendor, isActive, status) => {
  const { store } = useContext(Context);
  const register = new RegisterUser(store);
  const item = new NewItem(store);

  if (isAuth) {
    if (status === 'inactive') {
      return (
        <Routes>
          <Route path="/inactive" element={<InactivePage />} />
          <Route path="*" element={<Navigate to="/inactive" />} />
        </Routes>
      );
    }

    if (!isVendor) {
      return (
        <Routes>
          <Route path="/registershop" element={<RegisterVendorPage />} />
          <Route path="*" element={<Navigate to="/registershop" />} />
        </Routes>
      );
    }

    if (!isActive) {
      return (
        <Routes>
          <Route element={<MainPage />}>
            <Route path="noconfirm" element={<NoConfirmPage />} />
            <Route path="setup" element={<UserSetupPage />} />
            <Route path="*" element={<Navigate to="/noconfirm" />} />
          </Route>
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route index element={<VendorMainPage />} />
          <Route path="item/:id" element={<ItemPage />} />
          <Route path="edititem/:id" element={<ItemEditPage />} />
          <Route path="additem" element={<ItemAddPage itemStore={item} />} />
          <Route path="items" element={<ItemListPage />} />
          <Route path="orders" element={<OrdersListPage />} />
          <Route path="setup" element={<UserSetupPage />} />
          <Route path="shop" element={<VendorInfoPage />} />
          <Route path="shop/edit" element={<VendorEditPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/enter" element={<LoginPage />} />
      <Route path="/restore" element={<RestorePassword />} />
      <Route
        path="/register"
        element={<RegisterPage registerStore={register} />}
      />
      <Route path="/policy" element={<Policy />} />

      <Route path="*" element={<Navigate to="/enter" />} />
    </Routes>
  );
};

export default useRoutes;
