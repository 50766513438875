import { useEffect, useContext } from 'react';
import { Context } from '..';

export const useNotifications = (sessionId) => {
  const { store } = useContext(Context);

  const subscribe = async (id) => {
    const eventSource = new EventSource(
      // `http://localhost:5000/api/notifications/${id}`
      `https://api.turkpazar.ru/api/notifications/${id}`
    );

    eventSource.onmessage = function (event) {
      const { type } = JSON.parse(event.data);

      switch (type) {
        case 'order_change':
          store.getOrders();
          break;

        case 'message':
          break;

        default:
          console.log(type);
          break;
      }
    };

    return eventSource;
  };

  useEffect(() => {
    let ev;
    if (sessionId) {
      ev = subscribe(sessionId);
    }
    return () => {
      console.log(ev);
    };
  }, []);
};
