import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { Context } from '..';
import { useParams } from 'react-router-dom';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

const ItemPage = () => {
  const { messages } = useIntl();
  const { store } = useContext(Context);
  const itemId = useParams().id;

  useEffect(() => {
    store.getItem(itemId);
  }, []);

  return (
    <>
      <Flex
        p="20px"
        bg="white"
        borderRadius="md"
        gap="20px"
        flexDirection={['column', 'column', 'row']}
      >
        <Box w="300px" borderRadius="4px">
          {store.item?.images.length ? (
            <Image
              borderRadius="md"
              src={store.item?.images[0].link}
              boxSize="300px"
              objectFit="cover"
            />
          ) : (
            <Box bg="gray.200" w="300px" h="300px" borderRadius="4px"></Box>
          )}
          <Flex gap="10px" mt="10px">
            {store.item?.images.map((img) => (
              <Image
                key={img.id}
                borderRadius="md"
                src={img.link}
                boxSize="50px"
                objectFit="cover"
              />
            ))}
          </Flex>
        </Box>

        <Box flex="1">
          <Box mb="20px" p="5px" borderRadius="md" bg="#f4fae3">
            {store.item?.subcat.items_cat.name} - {store.item?.subcat.name} -{' '}
            {store.item?.destination}
          </Box>
          <Heading size="md">{store.item?.name}</Heading>
          <Text py="10px">{store.item?.description}</Text>
          <Divider />
          {messages.product_form_comment}
          <Box mb="20px" p="5px" borderRadius="md" bg="#f4fae3">
            {store.item?.comment || '-'}
          </Box>

          <Divider />
        </Box>

        <Box>
          <Box textAlign="right">
            <Heading size="xl" color="green">
              {store.item?.price} $
            </Heading>
            <Box>{store.item?.count} pcs/lot</Box>
          </Box>
          <Box
            mb="20px"
            mt="20px"
            p="20px"
            borderRadius="md"
            bg="#F3FFCA"
            justifyContent="space-between"
          >
            <Box>
              <Box>Package size:</Box>
              <Box>
                {store.item?.pkg_x} x {store.item?.pkg_y} x {store.item?.pkg_z}{' '}
                sm
              </Box>
            </Box>
            <Box>Weight {store.item?.pkg_w} kg</Box>
          </Box>
          <Button
            as={rLink}
            to={`/edititem/${itemId}`}
            w="full"
            mb="10px"
            bg="#f4fae3"
          >
            Edit
          </Button>
          {store.item?.show ? (
            <Button
              w="full"
              bg="#f4fae3"
              onClick={() => store.showHide(store.item.id, store.item.show)}
            >
              Hide
            </Button>
          ) : (
            <Button
              w="full"
              bg="#f4fae3"
              onClick={() => store.showHide(store.item.id, store.item.show)}
            >
              Show
            </Button>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default observer(ItemPage);
