import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  VStack,
  Stack,
  Button,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useValidation from '../hooks/useValidation';
import useMessage from '../hooks/useMessage';
import { useIntl } from 'react-intl';

const VendorForm = ({ vendorStore }) => {
  const { messages } = useIntl();
  const name = useValidation(vendorStore.form.name, { isEmpty: true });
  const ur_type = useValidation(vendorStore.form.ur_type, { isEmpty: true });
  const inn = useValidation(vendorStore.form.inn, { isEmpty: true });
  const rs = useValidation(vendorStore.form.rs, { isEmpty: true });
  // const tax_com = useValidation(vendorStore.form.tax_com, { isEmpty: true });
  const actual_adress = useValidation(vendorStore.form.actual_adress, {
    isEmpty: true,
  });
  const registered_adress = useValidation(vendorStore.form.registered_adress, {
    isEmpty: true,
  });
  const email = useValidation(vendorStore.form.email, {
    isEmpty: true,
    isEmail: true,
  });
  // const tel = useValidation(vendorStore.form.tel, { isEmpty: true });
  const index = useValidation(vendorStore.form.index, { isEmpty: true });
  const country = useValidation(vendorStore.form.country, { isEmpty: true });
  const city = useValidation(vendorStore.form.city, { isEmpty: true });
  const district = useValidation(vendorStore.form.district, { isEmpty: true });
  const ceo_name = useValidation(vendorStore.form.ceo_name, { isEmpty: true });
  const ceo_tel = useValidation(vendorStore.form.ceo_tel, { isEmpty: true });

  const [copy, setCopy] = useState(false);

  useMessage(vendorStore.error);

  const changeHandler = (e) => {
    vendorStore.setForm(e.target.name, e.target.value);
    if (copy && e.target.name == 'actual_adress') {
      vendorStore.setForm('registered_adress', e.target.value);
    }
  };

  const copyAddress = () => {
    setCopy(!copy);
  };
  useEffect(() => {
    if (copy) {
      vendorStore.setForm('registered_adress', vendorStore.form.actual_adress);
    } else {
      vendorStore.setForm('registered_adress', '');
    }
  }, [copy]);

  return (
    <Box>
      <VStack gap="10px">
        <FormControl isRequired>
          <FormLabel>{messages.vendor_name}</FormLabel>
          <Input
            type="text"
            name="name"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.name}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_description}</FormLabel>
          <Textarea
            name="description"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.description}
            isDisabled={vendorStore.loading}
          />
        </FormControl>

        {/* <FormControl>
          <FormLabel>Products category</FormLabel>
          <CheckboxGroup
            colorScheme="green"
            onChange={changeHandler}
            name="product_types"
            value={vendorStore.form.product_types}
            isDisabled={vendorStore.loading}
          >
            <Stack spacing={[1, 5]} direction={['column', 'row']}>
              <Checkbox value="naruto">Одежда</Checkbox>
              <Checkbox value="sasuke">Обувь</Checkbox>
              <Checkbox value="kakashi">Электроника</Checkbox>
            </Stack>
          </CheckboxGroup>
        </FormControl> */}

        <FormControl isRequired>
          <FormLabel>{messages.vendor_ur_type}</FormLabel>
          <Select
            name="ur_type"
            placeholder={messages.vendor_select_ur_type}
            onChange={changeHandler}
            value={vendorStore.form.ur_type}
            isDisabled={vendorStore.loading}
          >
            <option value="Gerçek Kişi Tacir">Gerçek Kişi Tacir</option>
            <option value="İrtibat Bürosu">İrtibat Bürosu</option>
            <option value="Yabancı Şirket Şubesi">Yabancı Şirket Şubesi</option>
            <option value="Adi Ortaklık">Adi Ortaklık</option>
            <option value="Kooperatif">Kooperatif</option>
            <option value="Kollektif Şirket">Kollektif Şirket</option>
            <option value="Komandit Şirket">Komandit Şirket</option>
            <option value="Anonim Şirket">Anonim Şirket</option>
            <option value="Limited Şirket">Limited Şirket</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_inn}</FormLabel>
          <Input
            type="number"
            name="inn"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.inn}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_rs}</FormLabel>
          <Input
            type="number"
            name="rs"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.rs}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        {/* <FormControl isRequired>
          <FormLabel>Tax Committee</FormLabel>
          <Input
            name="tax_com"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.tax_com}
            isDisabled={vendorStore.loading}
          />
        </FormControl> */}
        <FormControl isRequired>
          <FormLabel>{messages.vendor_actual_address}</FormLabel>
          <Input
            type="text"
            name="actual_adress"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.actual_adress}
            isDisabled={vendorStore.loading}
          />
          <Checkbox onChange={copyAddress}>
            {messages.vendor_same_address}
          </Checkbox>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{messages.vendor_register_address}</FormLabel>
          <Input
            type="text"
            name="registered_adress"
            placeholder=""
            onChange={changeHandler}
            value={
              copy
                ? vendorStore.form.actual_adress
                : vendorStore.form.registered_adress
            }
            isDisabled={vendorStore.loading || copy}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_website}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="https://" />
            <Input
              type="text"
              name="site"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.form.site}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>E-mail</FormLabel>
          <Input
            type="text"
            name="email"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.email}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        {/* <FormControl isRequired>
          <FormLabel>Telephone number</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.form.tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl> */}
        {/* <FormControl>
          <FormLabel>Mobile phone number</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="mobile_tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.form.mobile_tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl> */}
        <FormControl isRequired>
          <FormLabel>{messages.vendor_zip}</FormLabel>
          <Input
            type="text"
            name="index"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.index}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_country}</FormLabel>
          <Input
            type="text"
            name="country"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.country}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_city}</FormLabel>
          <Input
            type="text"
            name="city"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.city}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_district}</FormLabel>
          <Input
            type="text"
            name="district"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.district}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_manager_name}</FormLabel>
          <Input
            type="text"
            name="manager_name"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.manager_name}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{messages.vendor_manager_tel}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="manager_tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.form.manager_tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_ceo_name}</FormLabel>
          <Input
            type="text"
            name="ceo_name"
            placeholder=""
            onChange={changeHandler}
            value={vendorStore.form.ceo_name}
            isDisabled={vendorStore.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{messages.vendor_ceo_tel}</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="ceo_tel"
              placeholder=""
              onChange={changeHandler}
              value={vendorStore.form.ceo_tel}
              isDisabled={vendorStore.loading}
            />
          </InputGroup>
        </FormControl>

        <Button
          colorScheme="green"
          px="40px"
          onClick={() => vendorStore.register()}
          isLoading={vendorStore.loading}
          isDisabled={
            name.err ||
            ur_type.err ||
            inn.err ||
            rs.err ||
            // tax_com.err ||
            actual_adress.err ||
            registered_adress.err ||
            email.err ||
            // tel.err ||
            index.err ||
            country.err ||
            city.err ||
            district.err ||
            ceo_name.err ||
            ceo_tel.err
          }
        >
          {messages.register_button}
        </Button>
      </VStack>
    </Box>
  );
};

export default observer(VendorForm);
