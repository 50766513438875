import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  Fade,
  InputRightElement,
  InputGroup,
  Input,
  IconButton,
  Divider,
  Heading,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

const Specifications = ({ store }) => {
  const { messages } = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [spec, setSpec] = useState('');
  const [specList, setList] = useState([]);
  const [variant, setVariant] = useState('');
  const [varList, setVarList] = useState([]);
  const [varInput, setInput] = useState(false);

  const specInput = useRef(null);
  const variantInput = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setList([]);
      setInput(false);
      setSpec('');
      setVariant('');
      setVarList([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (spec) searchSpec();
  }, [spec]);

  const searchSpec = async () => {
    const list = await store.findSpec(spec);
    setList(list);
  };

  const enterKey = (e) => {
    if (e.key === 'Enter') {
      specInput.current.blur();
      if (spec) {
        setInput(true);
      }
    }
  };

  const addVariant = () => {
    if (!varList.includes(variant) && variant)
      setVarList([...varList, variant]);
    setVariant('');
  };

  const variantEnter = (e) => {
    if (e.key === 'Enter') {
      if (variant) {
        addVariant();
      }
    }
  };

  const delVariant = (el) => {
    const filter = varList.filter((item) => item !== el);
    setVarList(filter);
  };

  return (
    <>
      <Flex>
        {store.specifications.map((item) => (
          <Box
            key={item.name}
            p="10px"
            border="1px"
            borderColor="gray.300"
            mb="20px"
            borderRadius="md"
          >
            <Flex gap="20px">
              <Box flex="1" as="strong" mb="10px">
                {item.name}
              </Box>
              <IconButton
                size="xs"
                onClick={() => store.delSpec(item.name)}
                icon={<CloseIcon />}
              />
            </Flex>
            <Flex gap="10px">
              {item.value.map((el) => (
                <Tag key={el} colorScheme="yellow">
                  {el}
                </Tag>
              ))}
            </Flex>
          </Box>
        ))}
      </Flex>
      <Button onClick={onOpen}>{messages.product_form_add_spec}</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{messages.product_form_spec_title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box>
              {varInput ? (
                <>
                  <Heading size="md" color="green">
                    {spec}
                  </Heading>
                  <Divider mb="10px" mt="10px" />
                </>
              ) : (
                <>
                  <InputGroup>
                    <Input
                      size="md"
                      value={spec}
                      onChange={(e) => setSpec(e.target.value.toLowerCase())}
                      onKeyDown={enterKey}
                      ref={specInput}
                    />
                    <InputRightElement>
                      <IconButton
                        onClick={() => {
                          if (spec) setInput(true);
                        }}
                        icon={<CheckIcon />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Fade in={!!specList.length}>
                    <Box p="10px" boxShadow="lg">
                      {!!specList.length &&
                        specList.map((item) => (
                          <Box
                            key={item.name}
                            onClick={() => {
                              setSpec(item.name);
                              setInput(true);
                              setList([]);
                            }}
                          >
                            {item.name}
                          </Box>
                        ))}
                    </Box>
                  </Fade>
                </>
              )}
            </Box>
            <Box>
              {varList.map((variant) => (
                <Tag key={variant} size="md" m="10px" p="4px">
                  <TagLabel>{variant}</TagLabel>
                  <TagCloseButton onClick={() => delVariant(variant)} />
                </Tag>
              ))}
              {varInput && (
                <InputGroup mt="20px">
                  <Input
                    ref={variantInput}
                    value={variant}
                    onKeyDown={variantEnter}
                    onChange={(e) => setVariant(e.target.value.toLowerCase())}
                  />
                  <InputRightElement>
                    <IconButton onClick={addVariant} icon={<CheckIcon />} />
                  </InputRightElement>
                </InputGroup>
              )}
            </Box>
          </ModalBody>

          <ModalFooter alignContent="center">
            <Button
              colorScheme="green"
              onClick={() => {
                store.setSpec({ name: spec, value: varList });
                onClose();
              }}
            >
              {messages.product_form_spec_button}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(Specifications);
