import { makeAutoObservable } from 'mobx';
import ItemService from '../service/ItemService';

export default class NewItem {
  error = null;
  message = null;
  loading = false;
  name = '';
  description = '';
  comment = '';
  subcat = '';
  pkg_x = '';
  pkg_y = '';
  pkg_z = '';
  pkg_w = '';
  destination = '';
  price = '';
  count = '';
  images = [];
  imgSize = 0;
  preview = [];
  parent = null;
  store = null;
  specifications = [];

  constructor(mainStore) {
    this.store = mainStore;
    makeAutoObservable(this);
  }

  setError(err) {
    this.error = err;
  }
  setMessage(msg) {
    this.message = msg;
  }
  setLoading(bool) {
    this.loading = bool;
  }

  setValue(e) {
    this[e.target.name] = e.target.value;
  }

  setSubcat(subcat) {
    this.subcat = subcat;
  }
  setCat(cat) {
    this.parent = cat;
  }

  setImages(images) {
    const files = this.images.map((f) => f.name);
    let compare = false;
    images.forEach((img) => {
      if (files.includes(img.name)) {
        compare = true;
        return;
      }
      this.imgSize += img.size;
      this.images.push(img);
    });
    if (!compare) this.setPreview(images);
  }

  setSpec(spec) {
    this.specifications.push(spec);
  }

  delSpec(name) {
    this.specifications = this.specifications.filter((el) => el.name !== name);
  }

  setPreview(images) {
    images.forEach((img) => {
      const image = URL.createObjectURL(img);
      this.preview.push({ id: img.size + img.lastModified, src: image });
    });
  }

  removePreview(id) {
    const filter = this.preview.filter((el) => el.id !== id);
    this.preview = filter;
  }

  removeImage(img) {
    const filter = this.images.filter(
      (el) => el.size + el.lastModified !== img
    );
    this.images = filter;
    let s = 0;
    this.images.forEach((item) => {
      s += item.size;
    });
    this.imgSize = s;
    this.removePreview(img);
  }

  async findSpec(string) {
    try {
      const { data } = await ItemService.getSpecifications(string);
      return data;
    } catch (e) {
      console.log(e.data);
    }
  }

  get formSize() {
    return Math.round(this.imgSize / (1024 * 1024));
  }

  async sendForm() {
    this.setLoading(true);
    try {
      const {
        error,
        loading,
        images,
        message,
        preview,
        specifications,
        store,
        imgSize,
        ...fields
      } = this;

      const formData = new FormData();

      for (const field in fields) {
        formData.append(field, fields[field]);
      }

      this.images.forEach((img) => {
        formData.append('images', img);
      });

      formData.append('specifications', JSON.stringify(specifications));

      const { data } = await ItemService.addItem(formData);
      this.setMessage(data.message);
      // this.clearData();
      // this.store.setItems([]);
    } catch (e) {
      this.store.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  clearData() {
    this.name = '';
    this.description = '';
    this.comment = '';
    this.subcat = '';
    this.pkg_x = '';
    this.pkg_y = '';
    this.pkg_z = '';
    this.pkg_w = '';
    this.destination = '';
    this.price = '';
    this.count = '';
    this.images = [];
    this.preview = [];
    this.specifications = [];
  }
}
