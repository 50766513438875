import React, { useEffect } from 'react';
import { Box, IconButton, Center, Grid } from '@chakra-ui/react';
import FileUpload from './FileUpload';
import { AddIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import AddImagesCard from './AddImagesCard';
import { useIntl } from 'react-intl';

const ImgForm = ({ store }) => {
  const { messages } = useIntl();
  const fileHendler = (e) => {
    store.setImages([...e.target.files]);
  };

  const removeFileHandler = (key) => {
    store.removeImage(key);
  };

  return (
    <Box
      gap="20px"
      alignItems="center"
      mt="20px"
      bg={store.formSize < 5 ? 'gray.100' : 'red.100'}
      p="20px"
      border="1px"
      borderRadius="md"
      borderColor={store.formSize < 5 ? 'gray.300' : 'red.300'}
    >
      {!!(store.formSize > 5) && (
        <Box color="red" textAlign="center" mb="10px">
          {messages.max_size}
        </Box>
      )}
      <Box>
        {store.preview.length ? (
          <Grid
            templateColumns={[
              'repeat(3, 1fr)',
              'repeat(5, 1fr)',
              'repeat(4, 1fr)',
              'repeat(5, 1fr)',
            ]}
            justifyItems="center"
            gap="10px"
          >
            {store.preview.map((img, i) => (
              <AddImagesCard
                key={img.id}
                img={img}
                handler={removeFileHandler}
              />
            ))}
            <FileUpload accept={'image/*'} multiple hendler={fileHendler}>
              {!!(store.formSize > 5) || (
                <IconButton
                  w={['80px', '100px', '150px']}
                  h={['80px', '100px', '150px']}
                  variant="outline"
                  aria-label="Add images"
                  icon={<AddIcon />}
                />
              )}
            </FileUpload>
          </Grid>
        ) : (
          <>
            <Center>{messages.product_form_images}</Center>
            <Center mt="10px">
              <FileUpload accept={'image/*'} multiple hendler={fileHendler}>
                <IconButton
                  variant="outline"
                  aria-label="Add images"
                  icon={<AddIcon />}
                />
              </FileUpload>
            </Center>
          </>
        )}
      </Box>
    </Box>
  );
};

export default observer(ImgForm);
