import { Flex, Heading, Box, Image } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import React from 'react';

const Logo = () => {
  return (
    <Flex
      alignItems="end"
      py={['4px', '10px']}
      px="28px"
      bg="white"
      borderBottomRadius="md"
    >
      <Heading
        color="gray.400"
        size={['sm', 'md', 'lg', 'lg']}
        fontWeight="normal"
      >
        Seller
      </Heading>
      <Box as={rLink} to="/">
        <Image h={['50px', '70px']} src="minLogo.svg" />
      </Box>
    </Flex>
  );
};

export default Logo;
