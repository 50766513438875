import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { Link as rLink } from 'react-router-dom';

const ItemMinCard = ({ item }) => {
  return (
    <Flex
      as={rLink}
      to={`/item/${item.id}`}
      gap="20px"
      mb="20px"
      borderRadius="md"
      alignItems="center"
    >
      <Box w="50px" h="50px" bg="gray.100">
        {!!item.images.length && (
          <Image boxSize="50px" objectFit="cover" src={item.images[0].link} />
        )}
      </Box>
      <Box flex="1">{item.name}</Box>
      <Box>
        <Box as="strong">
          {item.price}$ / {item.count}pcs.
        </Box>
      </Box>
    </Flex>
  );
};

export default ItemMinCard;
