import React from 'react';
import { Center, Box } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

const NoConfirmPage = () => {
  const { messages } = useIntl();
  return (
    <Center>
      <Box>{messages.page_no_confirm}</Box>
    </Center>
  );
};

export default NoConfirmPage;
