import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '..';

const ConfirmForm = ({ opened, close, orderId, itemId, loading }) => {
  const { store } = useContext(Context);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [items, setItems] = useState('');
  const [comment, setComment] = useState('');

  return (
    <>
      <Modal
        isOpen={opened}
        onClose={() => {
          onClose();
          close(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit answer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="20px">
              <FormLabel>Item count</FormLabel>
              <Input value={items} onChange={(e) => setItems(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Comment</FormLabel>
              <Textarea onChange={(e) => setComment(e.target.value)}></Textarea>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                store.checkOrderItem(itemId, {
                  vendorCount: items,
                  comment,
                });
                close(false);
              }}
              isLoading={loading}
            >
              Confirm
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                close(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ConfirmForm);
