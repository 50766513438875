import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  IconButton,
  Heading,
  VStack,
  Flex,
  Tag,
  Center,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import VendorData from '../components/VendorData';
import { Context } from '..';
import { Link as rLink } from 'react-router-dom';
import VendorEditForm from '../components/VendorEditForm';

const VendorEditPage = () => {
  const { store } = useContext(Context);
  return (
    <Flex direction="column" align="center">
      <Box w="90%" maxW="800px" m="auto">
        <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
          <VStack spacing="20px">
            <VendorEditForm vendorStore={store} />
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default VendorEditPage;
