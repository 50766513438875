import { Box, IconButton, Image } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';

const AddImagesCard = ({ img, handler }) => {
  return (
    <Box
      w={['80px', '100px', '150px']}
      h={['80px', '100px', '150px']}
      bgImage={img.src}
      bgSize="cover"
      borderRadius="md"
      border="1px"
      borderColor="gray.300"
      onClick={() => handler(img.id)}
    >
      <IconButton size="xs" variant="ghost" icon={<CloseIcon />} />
    </Box>
  );
};

export default AddImagesCard;
