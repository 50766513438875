import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Flex,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  InputRightElement,
  InputGroup,
  Link,
  Spacer,
  Text,
  Center,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as rLink } from 'react-router-dom';
import { Context } from '..';
import useInput from '../hooks/useInput';
import Social from '../components/Social';
import { FormattedMessage, useIntl } from 'react-intl';

const LoginPage = () => {
  const { messages } = useIntl();

  const { store } = useContext(Context);
  const login = useInput('', { isEmpty: true });
  const password = useInput('', { isEmpty: true });

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const authHandler = () => {
    store.login(login.props.value, password.props.value);
  };

  return (
    <>
      <Flex direction="column" mt="100px" align="center">
        <Box w="90%" maxW="500px" m="auto">
          <Box bg="white" borderRadius="md" mb="20px" p="30px" boxShadow="lg">
            <VStack spacing="20px">
              <Box>
                <Heading size="md">
                  <FormattedMessage id="login_form_title" />
                </Heading>
              </Box>
              <Divider />
              <FormControl>
                <FormLabel>
                  <FormattedMessage id="form_login_label" />
                </FormLabel>
                <Input
                  placeholder={messages.form_login_placeholder}
                  name="login"
                  {...login.props}
                  isDisabled={store.loading}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Flex alignItems="baseline">
                    <Text>
                      <FormattedMessage id="form_pass_label" />
                    </Text>
                    <Spacer />
                    <Link
                      as={rLink}
                      to="/restore"
                      fontSize="xs"
                      color="gray.400"
                    >
                      <FormattedMessage id="forgot_button" />
                    </Link>
                  </Flex>
                </FormLabel>
                <InputGroup>
                  <Input
                    type={show ? 'text' : 'password'}
                    name="password"
                    placeholder={messages.form_pass_placeholder}
                    {...password.props}
                    isDisabled={store.loading}
                  />
                  <InputRightElement>
                    <Button
                      variant="ghost"
                      color="gray.600"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                colorScheme="green"
                px="40px"
                onClick={authHandler}
                isLoading={store.loading}
                isDisabled={login.err || password.err}
              >
                <FormattedMessage id="login_button" />
              </Button>

              <Button
                as={rLink}
                size="xs"
                variant="ghost"
                w="full"
                to="/register"
              >
                <FormattedMessage id="register_button" />
              </Button>
            </VStack>
          </Box>
          <Center>
            <Social />
          </Center>
        </Box>
      </Flex>
    </>
  );
};

export default observer(LoginPage);
