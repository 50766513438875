import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, Button, Center, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useAutoload from '../hooks/useAutoload';
import useTrim from '../hooks/useTrim';

const Pagination = ({ itemsCount, isLoading, callback }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const last = useRef();

  const allPages = useMemo(() => {
    const pageItems = Math.ceil(itemsCount / limit);
    const totalPages = [];
    for (let i = 0; i < pageItems; i++) {
      totalPages.push(i + 1);
    }
    return totalPages;
  }, [itemsCount]);

  const [trimPages, showLeft, showRight] = useTrim(page, allPages);

  useEffect(() => {
    callback({ limit, page });
  }, [page]);

  const hendler = (next = true) => {
    if (next) setPage(page + 1);
    else setPage(page - 1);
  };

  // useAutoload(last, isLoading, hendler);

  if (itemsCount > limit) {
    return (
      <>
        <div ref={last} style={{ height: 20 }}></div>
        <Flex gap="20px" justifyContent="center" mt="40px" flexWrap="wrap">
          {showLeft && (
            <Flex
              bg="blackAlpha.50"
              boxSize="26px"
              justifyContent="center"
              borderRadius="full"
              fontSize="small"
              alignItems="center"
              as={Link}
              onClick={() => hendler(false)}
            >
              ...
            </Flex>
          )}
          {allPages.map((item) => (
            <Flex
              key={item}
              bg={item == page ? '#a1c41b' : 'blackAlpha.100'}
              boxSize="26px"
              justifyContent="center"
              borderRadius="full"
              fontSize="small"
              alignItems="center"
              textColor={item == page ? 'white' : 'black'}
              as={Link}
              onClick={() => setPage(item)}
            >
              {item}
            </Flex>
          ))}
          {showRight && (
            <Flex
              bg="blackAlpha.50"
              boxSize="26px"
              justifyContent="center"
              borderRadius="full"
              fontSize="small"
              alignItems="center"
              as={Link}
              onClick={hendler}
            >
              ...
            </Flex>
          )}
        </Flex>
        {/* <Center mt="30px">
          <Link onClick={hendler} size="xs" variant="ghost">
            Загрузить еще
          </Link>
        </Center> */}
      </>
    );
  }

  return <></>;
};

export default Pagination;
